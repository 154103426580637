import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { format, addDays, parse } from 'date-fns';
interface LeaveData {
  leaveType: string;
  leaveDates?: string[]; 
  reason: string;
  fileUrl: string;
  id:any;
}

@Component({
  selector: 'app-onuser-end-leave',
  templateUrl: './onuser-end-leave.component.html',
  styleUrls: ['./onuser-end-leave.component.css','/../../../shared/all-style/add-leave-style.component.css']
})
export class OnuserEndLeaveComponent implements OnInit {
  id = localStorage.getItem('id') || '';
  leaveData: LeaveData = {
    id:'',
    leaveType: '',
    leaveDates: [],
    reason: '',
    fileUrl: ''
  };
  selectedDate: string = '';
  selectedFile: File;
  selectedRange: { startDate: Date, endDate: Date } | null = null;
  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log( this.leaveData.id ,this.leaveData ,"chehking data")
    this.route.queryParams.subscribe(params => {
      if (params['id']) this.leaveData.id = params['id'];
      if (params['leaveType']) this.leaveData.leaveType = params['leaveType'];
      if (params['reason']) this.leaveData.reason = params['reason'];
      if (params['startDate'] && params['endDate']) {
        this.selectedRange = { 
          startDate: parse(params['startDate'], 'dd-MMM-yyyy', new Date()), 
          endDate: parse(params['endDate'], 'dd-MMM-yyyy', new Date()) 
        };
      }
      if (params['fileUrl']) this.leaveData.fileUrl = params['fileUrl']; // Set file URL if passed
    });
  }
  

  onSelect(event: any): void {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024; // 300 KB

    if (file.size > maxFileSize) {
      Swal.fire('Error', 'File size exceeds 5 MB limit. Please upload a file smaller than 300 KB.', 'error');
      return;
    }

    this.selectedFile = file;
  }

  uploadFile(): Promise<string> {
    const folderName = 'leavesDocs';
    const data = new FormData();
    data.append('file', this.selectedFile);

    return new Promise((resolve, reject) => {
      this.mainService.uploadFile(this.selectedFile, folderName).subscribe(
            (response: any) => {
              if (response && response.message) {  // Ensure response has a valid URL
                const filePath = response.message;
                this.leaveData.fileUrl = filePath; // Assign properly
                resolve(filePath);
              } else {
                Swal.fire('Error', 'Invalid file URL received from server.', 'error');
                reject('Invalid file URL');
              }
            },
            (error) => {
              console.error('File upload failed:', error);
              Swal.fire('Error', 'File upload failed.', 'error');
              reject(error);
            }
          );
        });
      }
  addDate(): void {
    if (this.selectedDate && !this.leaveData.leaveDates.includes(this.selectedDate)) {
      this.leaveData.leaveDates.push(this.selectedDate);
      this.selectedDate = '';
    }
  }

  removeDate(dateToRemove: string): void {
    this.leaveData.leaveDates = this.leaveData.leaveDates.filter(date => date !== dateToRemove);
  }

  async saveLeaveForm(): Promise<void> {
    try {
      if (this.selectedFile) {
        const fileUrl = await this.uploadFile();
        this.leaveData.fileUrl = fileUrl;
      }
     
      if (this.leaveData.id) {
        // Update existing leave request
        this.mainService.updateUserLeave(this.leaveData.id, this.leaveData).subscribe(
          (data: any) => {
            Swal.fire('Success', 'Leave request updated successfully!', 'success');
            this.router.navigate(['user-dashboard/user-leave']);
          },
          (error) => {
            console.error('Error:', error);
            Swal.fire('Error', 'Failed to update leave request. ' + error.error.message, 'error');
          }
        );
      }
      else{
      this.mainService.userrequestleave(this.id, this.leaveData).subscribe(
        (data: any) => {
          Swal.fire('Success', 'Leave request submitted successfully!', 'success');
          this.router.navigate(['user-dashboard/user-leave']);
        },
        (error) => {
          console.error('Error:', error);
          Swal.fire('Error', 'Failed to submit leave request. ' + error.error.message, 'error');
        }
      );
    }
    } catch (error) {
      console.error('Error in saving leave form:', error);
      Swal.fire('Error', 'An unexpected error occurred while saving the leave form.', 'error');
    }
  }
  onDateRangeChange(selectedRange: { startDate: moment.Moment, endDate: moment.Moment }): void {
    this.leaveData.leaveDates = []; // Clear previous dates
    if (selectedRange.startDate && selectedRange.endDate) {
        let currentDate = selectedRange.startDate.startOf('day');
        const endDate = selectedRange.endDate.endOf('day');

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
            const formattedDate = currentDate.format('YYYY-MM-DD');
            this.leaveData.leaveDates.push(formattedDate);
            currentDate = currentDate.add(1, 'day');
        }
    }
    console.log('Selected Range:', selectedRange);
    console.log('Leave Dates:', this.leaveData.leaveDates);
}
}
