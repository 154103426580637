import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { MatDialog } from '@angular/material/dialog';
import { Renderer2 } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Chart } from 'chart.js';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MettingModalComponent } from '../../admin/metting-modal/metting-modal.component';

@Component({
  selector: 'app-user-welcome',
  templateUrl: './user-welcome.component.html',
  styleUrls: ['./user-welcome.component.css']
})
export class UserWelcomeComponent implements OnInit {
  totalPendingTasks: number = 0;
  todayMeetingCount: number = 0;
  userAllRequest: number = 0;
  userMonthlyAttendance: number = 0;
  id = localStorage.getItem('id');
  modalVisible: boolean = false;
  refreshToken: string = '';
  status!: number;
  startDate!: number;
  endDate!: string;
  pageNumber!: number;
  pageSize!: number;
  month: any;
  taskCount: any;
  attendanceCount: any;
  projectdetails: any;
  attendance: any = {};
  break: any;
  isBackToWorkVisible: boolean = false;
  isModalOpen:boolean =false;
  @ViewChild('myChart', { static: true }) chartRef!: ElementRef<HTMLCanvasElement>;

  perform: any = {
    attendanceRatio: 0,
    taskCompletionRatio: 0,
    completedTasks: 0,
    pendingTasks: 0,
    totalTasks: 0,
    averagePunchInTime: 0
  };

  reasons = ['Lunch', 'Tea Break', 'Personal Work', 'Parcel or Bank','Meeting'];
  isForm1Visible: boolean = false;
  users: any;

  breakForm = new FormGroup({
    reason: new FormControl('', Validators.required)
  });

  constructor(
    private mainService: MainService,
    private login: LoginService,
    private dialog:MatDialog
  ) {}

  ngOnInit(): void {
    this.refreshToken = this.login.getToken();
    this.refreshUserToken();
    this.loadPerformanceData();
    this.Attendance();
    this.TaskCount();
    this.getProjectDetails();
    this.getTodayAttendance();

    setTimeout(() => {
      this.openModal();
      this.renderChart();
    }, 1000);
  }

  refreshUserToken() {
    this.login.refreshToken(this.refreshToken).subscribe(
      (res: any) => {
        this.refreshToken = res.token;
        console.log("Token refreshed:", this.refreshToken);
      },
      (error) => console.error("Error refreshing token:", error)
    );
  }

  Attendance() {
    this.mainService.mothlyAttendanceCount(this.id).subscribe(
      (res: any) => this.attendanceCount = res,
      (error) => console.error("Error fetching attendance:", error)
    );
  }

  getProjectDetails() {
    this.mainService.getAllAssigmentbyUser(this.id, this.status, this.startDate, this.endDate, this.pageNumber, this.pageSize)
      .subscribe(
        (project: any) => {
          this.projectdetails = project.content;
          console.log("Projects:", project);
        },
        (error) => console.error("Error fetching project details:", error)
      );
  }

  TaskCount() {
    this.mainService.taskpending(this.id).subscribe(
      (res: any) => this.taskCount = res,
      (error) => console.error("Error fetching task count:", error)
    );
  }

  loadPerformanceData() {
    this.mainService.performance(this.id, this.month).subscribe(
      (res: any) => {
        this.perform = res;
        console.log("Performance:", this.perform);
      },
      (error) => console.error("Error fetching performance:", error)
    );
  }

  renderChart(): void {
    if (!this.chartRef?.nativeElement) {
      console.error("Chart reference not found.");
      return;
    }

    new Chart(this.chartRef.nativeElement, {
      type: 'doughnut',
      data: {
        labels: ['Pending', 'Done'],
        datasets: [{
          label: 'Task Stats',
          data: [this.perform.pendingTasks, this.perform.completedTasks],
          backgroundColor: ['#63a0ff', '#dc3545'],
          hoverOffset: 4
        }]
      },
      options: {
        cutout: '70%',
        plugins: {
          legend: { display: false }
        }
      }
    });
  }

  openModal() {
    this.modalVisible = true;
  }

  getTodayAttendance() {
    this.mainService.getTodayAttendance(this.id).subscribe((res: any) => {
      if (res?.inTime) {
        this.attendance = {
          ...res,
          currectRecordId: res.serviceRecords?.id,
          formattedTime: this.mainService.toTime(res.inTime),
          formattedBreak: res.serviceRecords?.createdTime ? this.mainService.toTime(res.serviceRecords.createdTime) : null
        };
        this.isForm1Visible = !this.attendance.formattedBreak;
        this.isBackToWorkVisible = !!this.attendance.formattedBreak;
        console.log("Attendance Data:", this.attendance);
      }
    });
  }

  toggleForm() {
    this.isForm1Visible = !this.isForm1Visible;
    this.mainService.markAttendance(this.id).subscribe(
      (res: any) => {
        this.users = res;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: this.users.message
        });
        this.getTodayAttendance(); // Reload attendance instead of page refresh
      },
      (error) => {
        console.error("Error marking attendance:", error.error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.message
        });
      }
    );
  }

  reasonFrom() {
    if (this.breakForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please select a reason'
      });
      return;
    }

    const breakData = {
      ...this.breakForm.value,
      currectRecordId: this.attendance.currectRecordId
    };

    this.mainService.addBreakTime(breakData).subscribe(
      (res: any) => {
        this.break = res;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: this.break.message
        });
        this.getTodayAttendance(); // Reload attendance data
      },
      (error) => {
        console.error("Error adding break:", error.error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.message
        });
      }
    );
  }

  toggleBreakForm() {
    if (!this.attendance?.currectRecordId) {
      console.error("currectRecordId is missing");
      return;
    }

    const updatedRecord = {
      id:this.attendance.currectRecordId  // Get current timestamp
    };

    this.mainService.updateServiceRecord(this.attendance.currectRecordId, updatedRecord).subscribe(
      (res: any) => {
        console.log("Service record updated:", res);
        this.getTodayAttendance(); // Reload data instead of manual toggling
      },
      (error) => {
        console.error("Error updating service record:", error);
      }
    );
  }
  punchOut(){
        this.mainService.updateAttendance(this.id).subscribe(
          (res) => {
            this.users = res;
            Swal.fire(JSON.stringify(this.users.message));
            window.location.href = "#/user-dashboard";
            this.isForm1Visible = false;
            this.attendance.formattedTime = '';
          },
          
          (error) => {
            console.error(error.error.message);
            alert(JSON.stringify(error.error.message));
            window.location.reload();
          }
        );
      } 

  onReasonChange(event: any) {
    const selectedReason = event.target.value.split(":")[1]?.trim();
    console.log(selectedReason, "selectedReason");
    if (selectedReason === 'Meeting') {
      if (!this.isModalOpen) { // Make sure modal is not already open
        this.isModalOpen = true;
        this.dialog.open(MettingModalComponent, {
          width: '600px',
        }).afterClosed().subscribe(() => {
          this.isModalOpen = false; // Reset after modal is closed
        });
      }
    } else {
      this.isModalOpen = false;
    }
  }
}
