<div class="container-fluid">
    <div class="row">
      <!-- Left Column -->
      <div class="col-md-4 col-12">
        <div class="card">
          <h3 class="card-header">APPROVED</h3>
          <div class="card-body">
            <form class="d-flex" role="search">
              <input class="form-control me-2" style="border: 1px solid #343434;" type="search" placeholder="Search" aria-label="Search">
            </form>
            <div class="d-flex  gap-2 mt-2">
              <select class="form-select" aria-label="Default select example">
                <option selected>Open</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Threopoppopopppppppe</option>
              </select>
              <select class="form-select" aria-label="Default select example">
                <option selected>Open</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select class="form-select" aria-label="Default select example">
                <option selected>Open</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <select class="form-select" aria-label="Default select example">
                <option selected>Open</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div class="overflow-container">
            <div class="card card-small mt-2 w-100 mb-3" *ngFor="let hirings of hiring" (click)="showHiringDetails(hirings.id)" style="width: 100%;">
              <div class="card-body ">
                <h3 class="card-title">{{hirings.name}}</h3>
                <h6 class="card-subtitle text-body-secondary">{{hirings.createdTime | timeAgo}}</h6>
                <p class="card-text">Screening</p>
                <!-- <p class="card-text  me-2">Assigned to <span ><img  class="border border-1 rounded-circle me-2" src="../../../../assets/icons/Profile.png" alt=""/>Reeya Arya</span></p> -->
                <a href="#" class="card-link"><img class="color:green" src="../../../../assets/separted.png"/></a>
                <a href="#" class="btn btn-primary btn-sm match">93% Match</a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
  
      <!-- Right Column -->
      <div class="col-md-8 col-12" *ngIf="selectedHiringDetail">
        <div class="card" >
          <h3 class="card-header">CANDIDATE INFORMATION</h3>
          <div class="card-body" >
            <div class="card w-100 mb-3 " style="    background: black;">
              <div class="card-body">
                <h3 class="card-title">{{selectedHiringDetail.name}}</h3>
                <h6 class="card-subtitle text-body-secondary">Last Updated on: {{selectedHiringDetail.createdTime | timeAgo}}</h6>
                <div>
                  <mat-stepper>
                    <mat-step label="Step 1" state="phone">
                      <p>Put down your phones.</p>
                      <div>
                        <button mat-button matStepperNext>Next</button>
                      </div>
                    </mat-step>
                    <mat-step label="Step 2" state="chat">
                      <p>Socialize with each other.</p>
                      <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                      </div>
                    </mat-step>
                    <mat-step label="Step 3">
                      <p>You're welcome.</p>
                    </mat-step>
                    </mat-stepper>
                </div>
               <div class="d-flex align-items-center justify-content-between p-2">
                    <p class="card-text">Assigned Date : {{selectedHiringDetail?.assignedDate |timeAgo}}</p>
                     <p  class="text-white text-center" style="text-decoration: underline;">Assign to {{selectedHiringDetail.assignToName}}</p>
                  </div>
              </div>
            </div>
  
            <div class="card w-100 mb-3">
              <div class="card-body">
                <h3 class="card-title">Candidate Information</h3>
                <!-- <button (click)="toggleEdit()" class="btn btn-outline-primary position-absolute" style="top: 10px; right: 10px;">
                    {{ isEditable ? 'Cancel' : 'Edit' }}
                  </button> -->
                  <div class="row g-3">
                    <div class="col-md-6">
                      <div class="card-text2"> Full Name: {{selectedHiringDetail.name}}</div>
                    </div>
                    <div class="col-md-6">
                      <div class="card-text2"> Department: {{selectedHiringDetail.department}}</div>
                    </div>
                    <div class="col-md-6">
                      <div class="card-text2"> Designation: {{selectedHiringDetail.designation}}</div>
                    </div>
                    <div class="col-md-6">
                      <div class="card-text2" > Socials: 
                      
                          <a
                            *ngIf="hasUrl(selectedHiringDetail.urls, 'github')"
                            [href]="getUrl(selectedHiringDetail.urls, 'github')"
                            target="_blank"
                          >
                            <img src="../../../../assets/github.png" alt="GitHub" />
                          </a>
                          <a
                            *ngIf="hasUrl(selectedHiringDetail.urls, 'linkedin')"
                            [href]="getUrl(selectedHiringDetail.urls, 'linkedin')"
                            target="_blank"
                          >
                            <img src="../../../../assets/linkedin.png" alt="LinkedIn" />
                          </a>
                          <a
                            *ngIf="hasUrl(selectedHiringDetail.urls, 'portfolio')"
                            [href]="getUrl(selectedHiringDetail.urls, 'portfolio')"
                            target="_blank"
                          >
                            <img src="../../../../assets/twitter.png" alt="Portfolio" />
                          </a>
                       
                       
                    </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card-text2"> Resume: <a [href]="selectedHiringDetail.resume" target="_blank" >View Now</a></div>
                    </div>
                    <div class="col-md-6">
                      <div class="card-text2"> Status: {{selectedHiringDetail.progressStatus}}</div>
                      <!-- <select class="form-select card-text2 " id="validationCustom04" style="background: black; color: white; border:none"    required>
                        <option selected disabled value="">Choose...</option>
                        <option>...</option>
                      </select> -->
                    </div>
                    
                  </div>
                <!-- <form class="row g-3 needs-validation" novalidate>
                  <div class="col-md-6 col-12">
                    <input type="text" class="form-control white-input" id="validationCustom01" [(ngModel)]="selectedHiringDetail.name" value="Full Name" [readonly]="!isEditable"  required>
                    <div class="valid-feedback">
                      Looks good!
                    </div>
                  </div>
  
                  <div class="col-md-6 col-12">
                    
                    <input type="text" class="form-control white-input" id="validationCustom01"  [(ngModel)]="selectedHiringDetail.department" value="Department" [readonly]="!isEditable"  required>
                    <div class="valid-feedback">
                      Looks good!
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <input type="text" class="form-control white-input" id="validationCustom02" value="Designation"  [(ngModel)]="selectedHiringDetail.designation" [readonly]="!isEditable"  required>
                    <div class="valid-feedback">
                      Looks good!
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <input type="text" class="form-control white-input " id="validationCustom01" value="Socials" [readonly]="!isEditable"  required>
                    <div class="urls" style="display: flex;">
                        <a
                          *ngIf="hasUrl(hirings.urls, 'github')"
                          [href]="getUrl(candidate.urls, 'github')"
                          target="_blank"
                        >
                          <img src="../../../../assets/github.png" alt="GitHub" />
                        </a>
                        <a
                          *ngIf="hasUrl(hirings.urls, 'linkedin')"
                          [href]="getUrl(candidate.urls, 'linkedin')"
                          target="_blank"
                        >
                          <img src="../../../../assets/linkedin.png" alt="LinkedIn" />
                        </a>
                        <a
                          *ngIf="hasUrl(hirings.urls, 'portfolio')"
                          [href]="getUrl(candidate.urls, 'portfolio')"
                          target="_blank"
                        >
                          <img src="../../../../assets/twitter.png" alt="Portfolio" />
                        </a>
                      </div>
                    <div class="valid-feedback">
                      Looks good!
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <input type="text" class="form-control white-input" id="validationCustom02" value="Resume" [readonly]="!isEditable"  required>
                    <div class="valid-feedback">
                      Looks good!
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <select class="form-select" id="validationCustom04" style="background: black; color: white; border:none"    required>
                      <option selected disabled value="">Choose...</option>
                      <option>...</option>
                    </select>
                    <div class="invalid-feedback">
                      Please select a valid state.
                    </div>
                  </div>
  
                  <div class="col-12">
                    <button class="btn btn-primary btn-sm" [disabled]="!isEditable" type="submit">Submit form</button>
                  </div>
                </form> -->
              </div>
            </div>
  
            <div class="card w-100 mb-3">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <h3 class="card-title">Interview Status</h3>
                  <button class=" d-flex btn btn-secondary btn-sm align-items-center">  <img src="../../../../assets/add.png" alt="Profile"/>Feedback</button>
                </div>
                <div class="overflow-container">
                  <div class=""  *ngFor="let newfeedback of feed">
                    <div class="d-flex align-items-center gap-2">
                    <img class="border border-1 rounded-circle" src="../../../../assets/icons/Profile.png" alt="Profile" width="40" height="40" />
                    <p class="card-text">{{ newfeedback.feedback }}
                      {{ newfeedback.createdDate | date: 'short' }}
                    </p>
                  </div>
                 
                  </div>
                  
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  