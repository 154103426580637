import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-candidate-status',
  templateUrl: './candidate-status.component.html',
  styleUrls: ['./candidate-status.component.css']
})
export class CandidateStatusComponent implements OnInit {
  isEditable:boolean =false;
  constructor(private mainService :MainService) { }
  candidates: any[] = [];
  hiring: any;
  sortOrder: 'asc' | 'desc' = 'asc';
  sortedByDateAsc :'asc' | 'desc' = 'asc';
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  userId: any;
  vacancyId: any;
  isActiveVacancy: boolean | null = null;
  hiringAIStatus: string | null = null;
  status:string | null = null;
  startDate: any = null;
  endDate: any = null;
  designation: string | null = null;
  showFilters: boolean = false;
  showPopup = false;
  inputDateToString:any;
  inputDateFromString:any;
  urls:[];
  feed: any[] = [];
  selectedHiringDetail:any;
  ngOnInit(): void {
    this.fetchData();
  }
  fetchData(): void {
    this.inputDateFromString = this.mainService.toTimestamp(this.startDate);
    this.inputDateToString = this.mainService.toTimestamp(this.endDate);
    this.mainService
      .getAllhiring(
        this.userId,
        this.vacancyId,
        this.isActiveVacancy,
        this.hiringAIStatus,
        this.status,
        this.inputDateFromString,
        this.designation,
        this.inputDateToString,
        this.pageNumber,
        this.pageSize
      )
      .subscribe((res: any) => {
        this.hiring = res.content;
        // this.hiring.urls[] = this.hiring
        this.totalElements = res.totalElements;
        console.log("Hiring Data: ", this.hiring);
        console.log("candidtaes",this.hiring.feedbacks)
      });
  }
  showHiringDetails(hiringId: string): void {
    this.selectedHiringDetail = this.hiring.find((hiring: any) => hiring.id === hiringId);
    this.feed = this.selectedHiringDetail.feedbacks;
  }
  toggleEdit() {
    this.isEditable = !this.isEditable;
  }
  hasUrl(urls: string[] | null | undefined, type: string): boolean {
    return urls ? urls.some(url => this.getType(url) === type) : false;
  }

  getUrl(urls: string[], type: string): string | null {
    const url = urls.find(url => this.getType(url) === type);
    return url ? url : null;
  }
  getType(url: string): string {
    if (url.includes('github.com')) {
      return 'github';
    } else if (url.includes('linkedin.com')) {
      return 'linkedin';
    } else if (url.includes('x.com') || url.includes('twitter.com')) {
      return 'portfolio';
    }
    return '';
  }
}
