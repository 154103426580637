<div class="col-md-12 container2" style="width: 100%;position: relative;">  
        <div class="container">
          <div class="top-header">
          <div class="header">
            <h1>My Activity Stats</h1>
           
          </div>
          <div class="stats-container">
            <div class="stat">
              <h2>Avg. Punch in</h2>
              <h1>{{perform.averagePunchInTime}} </h1>
            </div>
            <div class="stat">
              <h2>Task Growth</h2>
              <h1><span style="color:#50C878;height: 2px;">↑</span>{{ isNaN(perform.taskCompletionRatio) ? 0 : perform.taskCompletionRatio | number:'1.2-2' }}%</h1>
            </div>
            <div class="stat">
              <h2>Tasks Done</h2>
              <h1>{{perform.completedTasks}}</h1>
              
            </div>
            <div class="action" style="margin-left: 30px;    display: block;">
              <span>Admin Review</span>
              <p style="   width:500px; background: #343333;
              height: 68px;
              padding: 10px;
              border-radius: 20px;
              overflow-y: auto;">{{perform.review}}</div>
          </div>
        </div>
        </div>
        <div class="container">
          <div class="row">
            <!-- First main container (col-md-6) -->
            <div class="col-md-6">
              <!-- First sub-container (col-md-12) -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header" style="font-size: 24px;">
                      Daily Onboarding
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-header">
                              Attendance <br>
                              <span >Mark Your Attendance</span>
                            </div>
                            <div class="card-body">
                             
                                <div class="back-design">
                               {{attendanceCount}} 
                              </div>
                              <button class="btn btn-primary" routerLink="mark-attendance">
                                >
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-header">
                              Daily Tasks<br>
                              <span> Set Your Daily Goals</span>
                            </div>
                            <div class="card-body">
                              <div class="back-design">
                                {{taskCount}} 
                               </div>
                              <button class="btn btn-primary" routerLink="add-task">
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Second sub-container (col-md-12) -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header" style="font-size: 24px;">
                      Projects
                    </div>
                    <div class="card-body">
                      <div class="row" *ngIf="projectdetails?.length > 0" style="overflow-y: auto; height:200px">
                        <div class="col-md-12">
                          <div class="card" *ngFor="let projectdetail of projectdetails">
                            <div class="card-header">
                              {{ projectdetail.subject }}
                              <br>
                              <span>Add Tasks for {{ projectdetail.subject }}</span>
                            </div>
                            <div class="card-body">
                              <button class="btn btn-primary" 
                                      [routerLink]="['/user-dashboard/user-assignment-details']" 
                                      [queryParams]="{ id: projectdetail.id }">
                                >
                              </button>
                            </div>
                          </div>
                        </div>
                    
                        <div *ngIf="projectdetails?.length === 0">
                          No projects found.
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
        
            <!-- Second main container (col-md-6) -->
            <div class="col-md-6">
              <!-- First sub-container (col-md-12) -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header" style="font-size: 24px;">
                      Task Stats
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 position-relative">
                          <canvas #myChart ></canvas>
                          <div class="chart-text">
                            <p>Total <br><span>{{perform.totalTasks}}</span></p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col-md-6">
                          <span class="legend-item">
                            <span class="legend-color" style="background-color: #63a0ff;"></span>
                            Pending
                          </span>
                        </div>
                        <div class="col-md-6">
                          <span class="legend-item">
                            <span class="legend-color" style="background-color: #dc3545;"></span>
                            Done
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- Second sub-container (col-md-12) -->
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header" style="font-size: 24px;">
                      Assigned Tasks
                    </div>
                    <div class="card-body">
                      Tasks assigned By Manager
                      <div class="alert ">
                        <span class="alert-icon">
                          !
                        </span>
                        No Task Assigned
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
       
</div>