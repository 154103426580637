import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { MettingModalComponent } from '../metting-modal/metting-modal.component';
@Component({
  selector: 'app-break-time',
  templateUrl: './break-time.component.html',
  styleUrls: ['./break-time.component.css'],
})
export class BreakTimeComponent implements OnInit {
  attendance: any = {};
  break: any;
  isBackToWorkVisible: boolean = false;
  reasons = ['Lunch', 'Tea Break', 'Personal Work', 'Parcel or Bank','Meeting'];
  isMeetingSelected: boolean = false;
  isForm1Visible: boolean = false;
  users: any;
  id = localStorage.getItem('id');
  isModalOpen = false;
  breakForm = new FormGroup({
    reason: new FormControl('', Validators.required),
  });
 
  constructor(private mainService: MainService,private dialog:MatDialog) {}

  ngOnInit(): void {
    this.getTodayAttendance();
    // onReasonChange();
  }

  getTodayAttendance() {
    this.mainService.getTodayAttendance(this.id).subscribe((res: any) => {
      if (res?.inTime) {
        this.attendance = {
          ...res,
          currectRecordId: res.serviceRecords?.id,
          formattedTime: this.mainService.toTime(res.inTime),
          formattedBreak: res.serviceRecords?.createdTime
            ? this.mainService.toTime(res.serviceRecords.createdTime)
            : null,
        };
        this.isForm1Visible = !this.attendance.formattedBreak;
        this.isBackToWorkVisible = !!this.attendance.formattedBreak;
        console.log('Attendance Data:', this.attendance);
      }
    });
  }

  toggleForm() {
    this.isForm1Visible = !this.isForm1Visible;
    this.mainService.markAttendance(this.id).subscribe(
      (res: any) => {
        this.users = res;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: this.users.message,
        });
        this.getTodayAttendance(); // Reload attendance instead of page refresh
      },
      (error) => {
        console.error('Error marking attendance:', error.error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.message,
        });
      }
    );
  }

  reasonFrom() {
    if (this.breakForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please select a reason',
      });
      return;
    }

    const breakData = {
      ...this.breakForm.value,
      currectRecordId: this.attendance.currectRecordId,
    };

    this.mainService.addBreakTime(breakData).subscribe(
      (res: any) => {
        this.break = res;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: this.break.message,
        });
        this.getTodayAttendance(); // Reload attendance data
      },
      (error) => {
        console.error('Error adding break:', error.error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.message,
        });
      }
    );
  }

  toggleBreakForm() {
    if (!this.attendance?.currectRecordId) {
      console.error('currectRecordId is missing');
      return;
    }

    const updatedRecord = {
      id: this.attendance.currectRecordId, // Get current timestamp
    };

    this.mainService
      .updateServiceRecord(this.attendance.currectRecordId, updatedRecord)
      .subscribe(
        (res: any) => {
          console.log('Service record updated:', res);
          this.getTodayAttendance(); // Reload data instead of manual toggling
        },
        (error) => {
          console.error('Error updating service record:', error);
        }
      );
  }

  punchOut() {
    this.mainService.updateAttendance(this.id).subscribe(
      (res) => {
        this.users = res;
        Swal.fire(JSON.stringify(this.users.message));
        window.location.reload();
        this.isForm1Visible = false;
        this.attendance.formattedTime = '';
      },

      (error) => {
        console.error(error.error.message);
        alert(JSON.stringify(error.error.message));
        window.location.reload();
      }
    );
  }

  onReasonChange(event: any) {
    const selectedReason = event.target.value.split(":")[1]?.trim();
    console.log(selectedReason, "selectedReason");
    if (selectedReason === 'Meeting') {
      if (!this.isModalOpen) { // Make sure modal is not already open
        this.isModalOpen = true;
        this.dialog.open(MettingModalComponent, {
          width: '600px',
        }).afterClosed().subscribe(() => {
          this.isModalOpen = false; // Reset after modal is closed
        });
      }
    } else {
      this.isModalOpen = false;
    }
  }
  
}
