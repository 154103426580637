import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ChangeDetectorRef } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLoggedIn: boolean = false; 
  isMenuOpen: boolean = false;
  isDropdownOpen :boolean = false;
  isSidebarOpen :boolean = true;
  user: any = null;
  userRole:any;
  token: string | null = null;
  constructor(public login: LoginService, private router: Router,private cdRef: ChangeDetectorRef,private mainService:MainService) {}

  ngOnInit(): void {
    // this.login.token$.subscribe(token => {
    //   this.isLoggedIn = !!token;
    // });
    //   // Subscribe to user changes
    //   this.login.user$.subscribe(user => {
    //     this.user = user;
    //     this.userRole = user?.authorities?.[0]?.authority || null;
    //   });
    // this.token = localStorage.getItem('token') || sessionStorage.getItem('token');
    // console.log(this.token);
    this.checkLoginStatus();
    this.login.loginStatusSubject.asObservable().subscribe(() => {
      this.checkLoginStatus();
    });
  }
  checkLoginStatus() {
    this.isLoggedIn = this.login.isLoggedIn();
    if (this.isLoggedIn) {
      this.user = this.login.getUser();
      this.userRole = this.user?.authorities?.[0]?.authority || null;
    } else {
      this.user = null;
      this.userRole = null;
    }
  }

  logout() {
    Swal.fire({
      title: 'Confirm Sign Off',
      text: 'Are you sure you want to sign off?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, sign off',
      confirmButtonColor: '#ff4081',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.login.logout();
        this.checkLoginStatus();
        this.router.navigate(['/']);
        this.cdRef.detectChanges();
        this.isLoggedIn = false;
        this.isDropdownOpen = false;
      }
    });
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  toggleDropdown(){
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  toggleMenuSidebar(){
    this.mainService.toggleMenu();
  }
}
