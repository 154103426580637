<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header" style="font-size: 18px;">
                      Daily Tracking <br/>
                      <span>Manage your attendance</span>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="container-sm">
                            <div class="check-time">
                              <span>
                              <i class="fa-solid fa-clock"></i>
                              </span>
                              Check In
                            </div>
                            <div class="date-format">
                              {{ attendance?.formattedTime ? attendance?.formattedTime : '09:29 AM'  }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="container-sm">
                            <div class="check-time">
                              <span>
                              <i class="fa-solid fa-clock"></i>
                              </span>
                              Break Time
                            </div>
                            <div class="date-format">
                              
                              {{attendance.formattedBreak ?attendance.formattedBreak:'00:00 AM Hrs'}}
                            </div> 
                          </div>
                        </div>
                      </div>
                      <button class="punchIn" 
                      *ngIf="attendance?.outTime || !attendance?.formattedTime || attendance?.formattedTime === ''" 
                      (click)="toggleForm()">
                      Punch In
                    </button>

                      <div *ngIf="isForm1Visible && !attendance?.formattedBreak && !attendance?.outTime">
                        <form [formGroup]="breakForm" (ngSubmit)="reasonFrom()">
                          <div class="breakform check-time break form-group" >
                            <span>
                            <i class="fa-solid fa-clock"></i>
                            </span>
                            
                            <div class="breakform2">
                              <label>Reason: </label>
                             <!-- Reason Selection Dropdown -->
                            <select formControlName="reason" class="select-form form-control" (change)="onReasonChange($event)">
                              <option value="" disabled>Select Reason</option>
                              <option *ngFor="let reason of reasons" [ngValue]="reason">
                                {{ reason }}
                              </option>
                            </select>

                            </div>
                            
                          </div>
                          <div class="buttonBreak">
                          <button type="submit" class="btnBreak" >Take a break</button>
                          <button type="button" class="btnPunch" (click)="punchOut()">Punch Out</button>
                        </div>
                        </form>
                      </div>
                      <button *ngIf="attendance?.formattedBreak" class="btnBack" (click)="toggleBreakForm()">Back to Work</button>

                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header" style="font-size: 18px;">
                      Daily Tasks<br/>
                      <span>Set Your Daily Goals</span>
                    </div>
                    <div class="card-body" style="    text-align: center;">
                      <div class="row">
                        <div class="col-md-12">
                          <button class="btn btn-primary" routerLink="add-task">
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        
    </div>
</div>


<div *ngIf="isMeetingSelected" class="modal" tabindex="-1" role="dialog" style="display: block;">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Meeting Details</h5>
        <button type="button" class="close" (click)="isMeetingSelected = false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="meetingForm" (ngSubmit)="submitMeetingDetails()">
          <div class="form-group">
            <label for="clientName">Client Name:</label>
            <input formControlName="clientName" id="clientName" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="clientEmail">Client Email:</label>
            <input formControlName="clientEmail" id="clientEmail" type="email" class="form-control" />
          </div>
          <div class="form-group">
            <label for="clientPhone">Client Phone:</label>
            <input formControlName="clientPhone" id="clientPhone" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="clientAddress">Client Address:</label>
            <input formControlName="clientAddress" id="clientAddress" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="channelPartnerName">Channel Partner Name:</label>
            <input formControlName="channelPartnerName" id="channelPartnerName" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="feedback">Feedback:</label>
            <textarea formControlName="feedback" id="feedback" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <label for="projectDetails">Project Details:</label>
            <textarea formControlName="projectDetails" id="projectDetails" class="form-control"></textarea>
          </div>
          <div class="form-group">
            <label for="source">Source:</label>
            <input formControlName="source" id="source" type="text" class="form-control" />
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
