import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import baseUrl from './helper';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public loginStatusSubject = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  public getCurrentUser() {
    return this.http.get(`${baseUrl}/current-user`).pipe(
      tap((response: any) => {
        if (response?.authorities?.length > 0) {
          const userRole = response.authorities[0].authority; // Extracting role
          localStorage.setItem('userRole', userRole); // Storing in localStorage
        }
      })
    );
  }
  
  refreshToken(refreshToken: string) {
   return this.http.post<any>(`${baseUrl}/user/refresh-token`, { refreshToken }).pipe(
      tap(response => {
        console.log('Refresh Token Response:', response); 
      }),
      catchError(error => {
        console.error('Refresh Token Error:', error); 
        return throwError(error);
      })
    );
  }
    public generateToken(loginData: any) {
    return this.http.post(`${baseUrl}/generate-token`, loginData);
  }

  //login user: set token in localStorage
  public loginUser(token) {
    localStorage.setItem('token', token);

    return true;
  }
  

  //isLogin: user is logged in or not
  public isLoggedIn() {
    let tokenStr = localStorage.getItem('token');
    if (tokenStr == undefined || tokenStr == '' || tokenStr == null) {
      return false;
    } else {
      return true;
    }
  }

  // logout : remove token from local storage
  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return true;
  }

  //get token
  public getToken() {
    return localStorage.getItem('token');
  }

  //set userDetail
  public setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  //getUser
  public getUser() {
    let userStr = localStorage.getItem('user');
    if (userStr != null) {
      return JSON.parse(userStr);
    } else {
      this.logout();
      return null;
    }
  }

  //get user role

  public getUserRole() {
    let user = this.getUser();
    return user.authorities[0].authority;
  }
}
