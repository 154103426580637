import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-employee-task',
  templateUrl: './employee-task.component.html',
  styleUrls: ['./employee-task.component.css']
})
export class EmployeeTaskComponent implements OnInit {

  @ViewChild('chartCanvas', { static: true }) chartCanvas!: ElementRef;
  @Input() userId: any;
  data: any;
  review: any = {};
  pieChartData: any[] = [];
  colorScheme = {
    domain: ['rgba(234, 76, 76, 1)','rgba(89, 50, 234, 1)', 'rgba(76, 234, 133, 1)']
  };
  user: any;
  chartDimensions: number[] = [900, 400]; 

  constructor(private mainService: MainService, private datePipe: DatePipe, private login: LoginService) {}

  ngOnInit(): void {
    this.user = this.login.getUser();
    const departmentName = this.user.department;
    console.log("user details: " + JSON.stringify(this.user.department));
    this.loadReviewData(departmentName);
  }

  loadReviewData(departmentName) {
    this.mainService.department(departmentName).subscribe(
      (res: any) => {
        this.data = res;
        console.log("department details", this.data); // Log the fetched data
        this.prepareChartData();
        console.log("pieChartData", this.pieChartData); // Log the prepared chart data
      },
      (error) => {
        console.error("Error fetching review data:", error);
      }
    );
  }

  prepareChartData() {
    const totalTasks = this.data["Total Task"] || 0;
    const completedTasks = this.data["Completed Task"] || 0;
    const pendingTasks = this.data["Pending Task"] || 0;
  
    // const completedPercentage = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
    // const pendingPercentage = totalTasks > 0 ? (pendingTasks / totalTasks) * 100 : 0;
    // const totalTasks1 = totalTasks > 0 ? (totalTasks / totalTasks) * 100 : 0;
  
    this.pieChartData = [
      {
        name: "Pending Tasks",
        value: pendingTasks
      },
      {
        name: "Completed Tasks",
        value: completedTasks
      },
      {
        name: "Total Tasks",
        value: totalTasks
      }
    ];
  
    // Dynamically change chart width based on conditions
    this.updateChartWidth();
  }
  

  updateChartWidth() {
    const totalTasks = this.data["Total Task"] || 0;
    if (totalTasks > 0) {
      this.chartDimensions = [1000, 200];
    } else {
      this.chartDimensions = [1000, 200]; 
    }
  }
}
