  <header class="bg-black text-white">
    <nav class="flex justify-between items-center w-[92%] mx-auto">
      <!-- Logo Section -->
      <div class="flex items-center">
        <img class="w-[9rem]" src="../../../assets/HR CONSOLE HORIZONTAL copy.png" alt="HR Console" />
        <!-- <ion-icon 
        name="{{ isMenuOpen ? 'close' : 'menu' }}" 
        class="text-3xl cursor-pointer " 
        (click)="toggleMenuSidebar()">
      </ion-icon> -->
      </div>

    <!-- Navigation Links -->
    <div 
      class="navLinks duration-500 md:static absolute bg-black md:min-h-fit min-h-[60vh] left-0 transition-all ease-in-out" 
      [class.top-[-300%]]="!isMenuOpen" 
      [class.top-[100%]]="isMenuOpen"
    >
      <ul class="flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8 ml-10"> 
        <li *ngIf="!isLoggedIn"><a class="hover:text-gray-500 text-sm" href="#">Featured</a></li>
        <li *ngIf="!isLoggedIn"><a class="hover:text-gray-500 text-sm" href="#">Visit Website</a></li>
        <li *ngIf="!isLoggedIn"><a class="hover:text-gray-500 text-sm" href="#">Support</a></li>

        <!-- Login Button -->
        <li *ngIf="!isLoggedIn" class="md:hidden">
          <button 
            class="bg-[#454547] text-white px-5 py-2 rounded-full hover:bg-[#ce3262] border-spacing-2"
            routerLink="/login">
            Login
          </button>
        </li>

        <!-- Profile Dropdown in Toggler Menu -->
        <li *ngIf="isLoggedIn" class="md:hidden">
          <button 
            class="flex items-center gap-2 bg-[#454547] px-4 py-2 rounded-full w-full"
            (click)="toggleDropdown()">
            <img 
              [src]="user.profileImage ?? '../../../assets/default-profile.png'" 
              alt="Profile" 
              class="rounded-full h-8 w-8" 
            />
            <span class="capitalize">{{ user.firstName }}</span>
            <ion-icon name="chevron-down-outline" class="text-xl"></ion-icon>
          </button>

          <!-- Dropdown Menu -->
          <div *ngIf="isDropdownOpen" class="bg-[#212122] rounded-lg mt-2">
            <ul class="rounded-lg">
              <li *ngIf="userRole === 'ADMIN'" class="py-2 px-2 hover:bg-[#454547] border-b border-gray-500 text-sm">
                <a routerLink="admin/admin-profile">Admin Profile</a>
              </li>
              <li *ngIf="userRole === 'MANAGER'" class="py-2 px-2 hover:bg-[#454547] border-b border-gray-500 text-sm">
                <a routerLink="/manager-dashboard/manager-profile">Manager Profile</a>
              </li>
              <li *ngIf="userRole === 'USER'" class="py-2 px-2 hover:bg-[#454547] border-b border-gray-50 text-sm">
                <a routerLink="user-dashboard/user-profile">User Profile</a>
              </li>
              <li class="py-2 px-2 hover:bg-gray-700">
                <button (click)="logout()">Logout</button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <!-- Profile and Toggle Icon for Desktop -->
    <div class="hidden md:flex items-center gap-6">
      <button 
        *ngIf="!isLoggedIn" 
        class="bg-[#454547] text-white px-5 py-2 rounded-full hover:bg-[#ce3262] border-spacing-2"
        routerLink="/login">
        Login
      </button>
      <div *ngIf="isLoggedIn" class="relative">
        <button class="flex items-center gap-2 bg-[#454547] px-2   py-2 rounded-full" >
          <img 
            [src]="user.profileImage ?? '../../../assets/12.png'" 
            alt="Profile" 
            class="rounded-full h-6 w-6" 
          />
          <!-- (click)="toggleDropdown()" -->
          <span class="capitalize "><a class="sm-[2rem]" [routerLink]="
          userRole === 'ADMIN' ? '/admin' :
          userRole === 'MANAGER' ? '/manager-dashboard' :
          userRole === 'USER' ? '/user-dashboard' : null
          ">
            {{ user.firstName }}</a></span>
          <!-- <ion-icon name="chevron-down-outline" class="text-xl"></ion-icon> -->
        </button>
        <!-- Dropdown Menu -->
        <div *ngIf="isDropdownOpen" class="absolute right-0 bg-black text-white shadow-lg rounded-lg mt-2 w-40">
          <ul class="bg-[#212122] rounded-lg">
            <li *ngIf="userRole === 'ADMIN'" class="py-2 px-2 hover:bg-[#454547] border-b border-gray-500 text-sm">
              <a routerLink="admin/admin-profile">Admin Profile</a>
            </li>
            <li *ngIf="userRole === 'MANAGER'" class="py-2 px-2 hover:bg-[#454547] border-b border-gray-500 text-sm">
              <a routerLink="/manager-dashboard/manager-profile">Manager Profile</a>
            </li>
            <li *ngIf="userRole === 'USER'" class="py-2 px-2 hover:bg-[#454547] border-b border-gray-50 text-sm">
              <a routerLink="user-dashboard/user-profile">User Profile</a>
            </li>
            <li class="py-2 px-2 hover:bg-gray-700">
              <button (click)="logout()">Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Menu Toggle Icon -->
    <ion-icon 
      name="{{ isMenuOpen ? 'close' : 'menu' }}" 
      class="text-3xl cursor-pointer md:hidden" 
      (click)="toggleMenu()">
    </ion-icon>
  </nav>
</header>
