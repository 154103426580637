<div class="full-page"><h4>Update User Profile</h4>
<div class="container">
  <header>Personal Details</header>
  <div class="col-12" >
    <form (submit)="updateUserProfile($event)" style="opacity: 0.8">
      <div class="form first">
        <div class="details personal">
          <div class="fields">
           <div class="input-field">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                id="firstName"
                value="{{ data.firstName }}"
                required
              />
            </div>
            <div class="input-field">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                id="lastName"
                value="{{ data.lastName }}"
                required
              />
            </div>
            <div class="input-field">
              <label>User Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                id="userName"
                value="{{ data.userName }}"
                required
              />
            </div>
            <div class="input-field">
              <label>Date Of Birth</label>
              <input
                type="date"
                placeholder="Enter birth date"
                id="dob"
                value="{{ data.dob }}"
                required
              />
            </div>
            <div class="input-field">
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter your email"
                id="email"
                value="{{ data.email }}"
                required
              />
            </div>
            <div class="input-field">
              <label>Mobile Number</label>
              <input
                type="number"
                placeholder="Enter mobile number"
                id="phone"
                value="{{ data.phone }}"
                required
              />
            </div>
            <div class="input-field">
              <label>Current  Address</label>
              <input
                type="text"
                placeholder="Enter your Current Address"
                id="currentAddress"
                value="{{ data.currentAddress  }}"
                required
              />
            </div>
            
            <div class="input-field">
              <mat-label style="font-size: 15px; font-weight: 500;color:black"
                >Gender</mat-label
              >

              <mat-select
                [(ngModel)]="data.gender"
                id="gender"
                name="gender"
                required
              >
                <mat-option value="Male">Male</mat-option>
                <mat-option value="Female">Female</mat-option>
                <mat-option value="Other">Other</mat-option>
              </mat-select>
            </div>
            <div class="form-group">
              <label>Role</label>
              <select
              style="width: 100%; height: 50px; margin-top: 3px; border: 1px solid #e0e0e0; border-radius: 5px; color: #837676;"
              required
              id="role"
              name="role"
              [(ngModel)]="storedAuthority"
            >
              <option *ngFor="let role of roles" [value]="role.roleName">
                {{ role.roleName }}
              </option>
            </select>
            
            </div>
              <div class="input-field">
              <label>Department</label>
              <mat-select
                [(ngModel)]="data.department"
                name="department"
                value="data.department"
                required
              >
                <mat-option value="Management">Management</mat-option>
                <mat-option value="Hr">Human Resource</mat-option>
                <mat-option value="Finance">Finance</mat-option>
                <mat-option value="Sales">Sales</mat-option>
                <mat-option value="Marketing">Digital Marketing</mat-option>
                <mat-option value="IT & Development"
                  >IT & Development</mat-option
                >
              </mat-select>
            </div>
            <div 
              class="input-field"
              *ngIf="data.department === 'Management'"
            >
              <label>Designation</label>
              <mat-select
                [(ngModel)]="data.designation"
                value=""
                name="role"
                required
              >
               
                <mat-option value="General Manager"
                  >General Manager</mat-option
                >
                <mat-option value="Administrative Officer"
                  >Administrative Officer</mat-option
                >
                <mat-option value="Chief Executive Officer(CEO)"
                  >Chief Executive Officer (CEO)</mat-option
                >
                <mat-option value="Strategic Lead"
                  >Strategic Lead</mat-option
                >
                <mat-option value="Chief Operating Officer(COO)"
                >Chief Operating Officer (COO)</mat-option
              >
              <mat-option value="Chief Financial Officer(CFO)"
              >Chief Financial Officer (CFO)</mat-option
            >
              </mat-select>
              <!--  -->
            </div>

            <div 
              class="input-field"
              *ngIf="data.department === 'Hr'"
            >
              <label>Designation</label>
              <mat-select
                [(ngModel)]="data.designation"
                name="role"
                required
              >
                <mat-option value="HR Executive"
                  >HR Executive</mat-option
                >
                <mat-option value="HR Manager">HR Manager</mat-option>
                <mat-option value="Recruiter">Recruiter</mat-option>
                
              </mat-select>
            </div>

            <div
              class="input-field"
              *ngIf="data.department === 'Finance'"
            >
              <label>Designation</label>
              <mat-select
                [(ngModel)]="data.designation"
                name="role"
                required
              >
                <mat-option value="Accountant"
                  >Accountant</mat-option
                >
                <mat-option value="Finance Manager"
                  >Finance Manager</mat-option
                >
              
                <mat-option value="Account Executive"
                  >Account Executive</mat-option
                >
              </mat-select>
              
            </div>

            <div
              class="input-field"
              *ngIf="data.department === 'Sales'"
            >
              <label>Designation</label>
              <mat-select
                [(ngModel)]="data.designation"
                name="role"
                required
              >
                <mat-option value="Sales Executive"
                  >Sales Executive</mat-option
                >
                <mat-option value="Sales Manager"
                  >Sales Manager</mat-option
                >
                <mat-option value="Senior Sales Manager"
                  >Senior Sales Manager</mat-option
                >
                <mat-option value="P&L Manager">P&L Manager</mat-option>
                <mat-option value="General Manager"
                  >General Manager</mat-option
                >
                <mat-option value="Customer Relationship Manager(CRM)"
                  >Customer Relationship Manager(CRM)</mat-option
                >
                <mat-option value="Real Estate Aspirant "
                  >Real Estate Aspirant </mat-option
                >
                <mat-option value="Customer Care Representative "
                  >Customer Care Representative </mat-option
                >
                <mat-option value="Portfolio Manager "
                  >Portfolio Manager </mat-option
                >
                <mat-option value="Sales Head"
                  >Sales Head</mat-option
                >
                
              </mat-select>
              
            </div>

            <div
              class="input-field"
              *ngIf="data.department === 'Marketing'"
            >
              <label>Designation</label>
              <mat-select
                [(ngModel)]="data.designation"
                name="role"
                required
              >
                <mat-option value="Digital Marketing Manager"
                  >Digital Marketing Manager</mat-option
                >
                <mat-option value="SEO Executive"
                >SEO Executive</mat-option
              >
                <mat-option value="Search Engine Optimization(SEO)"
                  >Search Engine Optimization(SEO)</mat-option
                >
                <mat-option value="Campaign Manager"
                  >Campaign Manager</mat-option
                >
                <mat-option value="Graphic Designer"
                >Graphic Designer</mat-option
              >
                <mat-option value="Content Writer"
                  >Content Writer</mat-option
                >
                <mat-option value="Social Media Analyst"
                  >Social Media Analyst</mat-option
                >
                <mat-option value="Marketing Specialist"
                  >Marketing Specialist</mat-option
                >
                <mat-option value="Marketing Analyst"
                  >Marketing Analyst</mat-option
                >
                <mat-option value="Public Relation Manager"
                  >Public Relation Manager</mat-option
                >
                <mat-option value="Brand Manager"
                  >Brand Manager</mat-option
                >
                <mat-option value="Intern"
                >Intern</mat-option
              >
              </mat-select>
              
            </div>

            <div
              class="input-field"
              *ngIf="data.department === 'IT & Development'"
            >
              <label>Designation</label>
              <mat-select
                [(ngModel)]="data.designation"
                name="role"
                required
              >
                <mat-option value="Software Development Engineer"
                  >Software Development Engineer</mat-option
                >
                <mat-option value="Senior Web Developer"
                  > Senior Web Developer</mat-option
                >
                <mat-option value=" Web Developer"
                  > Web Developer</mat-option
                >
                <mat-option value="Full Stack Developer"
                  >Full Stack Developer</mat-option
                >
                <mat-option value="Senior Web Designer"
                  >Senior Web Designer</mat-option
                >
                <mat-option value="Junior Web Designer"
                  >Junior Web Designer</mat-option
                >
               
                <mat-option value="Backend-Develper"
                  >Backend-Develper</mat-option
                >
                <mat-option value="Frontend Developer"
                  >Frontend Developer</mat-option
                >
                <mat-option value="Senior App Developer/ Android Developer"
                  > Senior App Developer/ Android Developer</mat-option
                >
                <mat-option value="Junior App Developer/ Android Developer"
                  > Junior App Developer/ Android Developer</mat-option
                >
              </mat-select>
              
            </div>
            <button  mat-raised-button color="accent" type="submit">  Update </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
</div>