<h4>All Tasks</h4>
<div class="header-row" >
  <h5>Task List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>
  </div>
</div>

<div class="filter-form-wrapper" *ngIf="showFilterForm" >
 <div class="filter-btn">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="meetingFilter()" >
    <div class="form-group" >
      <label for="dateRange" >Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style=" margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
        />
      </div>
    </div>

    <div class="form-group" >
      <label for="status" >Status:</label><br>
      <select id="status" name="status" [(ngModel)]="status" class="form-control" class="selector">
        <option value="" disabled selected>Select Status</option>
        <option value="PENDING">Pending</option>
          <option value="DONE">Done</option>
          <option value="DUE">Due</option>
          <option value="REVIEWED">Reviewed</option>
          <option value="ASSIGNED">Assigned</option>
      </select>
    </div>
    <div class="form-group">
      <label for="status" >Select Employee:</label><br>
      <select id="userId" name="employeeId" [(ngModel)]="user_id" class="form-control" class="selector">
        <option value="" disabled selected>Select Employee</option>
        <option *ngFor="let employee of user" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div>
    <div class="filter-btn">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>
 
  <div class="container2" style="margin-top: 30px;">
    <div class="scrollable-content">
    <table>
      <thead>
        <tr>
          <th  style="width: 10%;">Date</th>
          <th style="width: 10%;">Name</th>
          <th>Task</th>
          <th style="width: 10%;">DeadLine</th>
          <th>Comments</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of data">
          <td >{{ data.createdTime | date : "dd-MMM-yyyy hh:mm a" }}</td>
          <td>{{ data.userName }}</td>
          <td style="display: flex;">{{ data.subject }}
            <ng-container *ngIf="data.fileUrl">
              <div  class="ml-2" *ngFor="let url of data.fileUrl">              
                <button (click)="openPopup(url)" style="background: none;border: none;">
                <img src="../../../../assets/task.png" alt="Document Icon" width="20" height="20" style="background: none;">
              </button>
            </div>

            </ng-container>
          </td>
          <td>{{ data.deadLine ? (data.deadLine | date:'dd-MMM-yyyy hh:mm a') : '..' }}</td>

          <td>
            <div id="existingComments">{{ data.comments }}</div>
          </td>
              <td [style.background-color]="getStatusColor(data.status)" style="opacity: 0.7;">{{ data.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
  <div class="pagination-container">
    <div class="pagination-info">
      Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
    </div>
    <mat-paginator 
      [length]="totalElements" 
      [pageSize]="pageSize" 
      [pageSizeOptions]="[10, 20, 50, 100]" 
      (page)="onPageChange($event)">
    </mat-paginator>
  </div>