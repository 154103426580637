<div class="full-page">
  <h4 style="margin-top: 10px">Add & Assign Task</h4>
  <mat-card-content>
    <div class="meetingContainer">
      <form (ngSubmit)="formSubmit()">
        <label for="userIds" style="color: white;">Select Employee:</label>
        <div class="form-field" style="background: rgb(49, 48, 48);">
          <select
            id="userIds"
            name="employeeSelector"
            [(ngModel)]="employee"
            style="background-color: #2d2d2d; border-radius: 6px; color: white; width: 100%; height: 39px; padding: 8px"
          >
            <option *ngFor="let employee of data" [value]="employee.id">{{ employee.userName }}</option>
          </select>
        </div>
        <div class="row" style="margin-top: 10px;">
          <div class="col">
            <label>Deadline Date</label>
            <input type="date" id="deadlineDate" class="form-control" [(ngModel)]="user.deadDate" required />
          </div>
          <div class="col">
            <label>Deadline Time</label>
            <input type="time" id="deadlineTime" class="form-control" [(ngModel)]="user.deadTime" required />
          </div>
        </div>
        <mat-form-field class="w100" appearance="outline" style="margin-top: 10px;">
          <mat-label style="color: white;">Task</mat-label>
          <textarea
            matInput
            name="Task"
            placeholder="Enter here"
            [(ngModel)]="user.subject"
            style="color: white;"
            rows="8"
            required
          ></textarea>
        </mat-form-field>
        <div>
          <label for="fileInput">Upload Files</label>
          <input type="file" id="fileInput" (change)="onSelect($event)" multiple />
          <div *ngFor="let file of selectedFiles; let i = index" class="file-item">
            <span>{{ file.name }} ({{ file.type }})</span>
            <button type="button" (click)="removeFile(i)">Remove</button>
          </div>
        </div>
        <div class="container text-center mt20">
          <button mat-raised-button color="accent">Add</button>
        </div>
      </form>
    </div>
  </mat-card-content>
</div>
