import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './pages/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HomeComponent } from './pages/home/home.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { authInterceptorProviders } from './services/auth.interceptor';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { UserPerformanceComponent } from './pages/admin/user-performance/user-performance.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { MatListModule } from '@angular/material/list';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { WelcomeComponent } from './pages/admin/welcome/welcome.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule,NgxUiLoaderConfig  } from 'ngx-ui-loader';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { UpdateSalaryComponent } from './pages/admin/update-salary/update-salary.component';
import { ShowEmployeeSalaryComponent } from './pages/admin/show-employee-salary/show-employee-salary.component';
// import { EmployeeAttendanceComponent } from './pages/admin/employee-attendance/employee-attendance.component';
import { AllEmployeeProfileComponent } from './pages/admin/all-employee-profile/all-employee-profile.component';
import { AddSalaryComponent } from './pages/admin/add-salary/add-salary.component';
import { AddUserProfileComponent } from './pages/admin/add-user-profile/add-user-profile.component';
import { UpdateUserProfileComponent } from './pages/admin/update-user-profile/update-user-profile.component';
import { SingleEmployeeAttendanceComponent } from './pages/admin/single-employee-attendance/single-employee-attendance.component';
import { ShowLeaveRequestComponent } from './pages/admin/show-leave-request/show-leave-request.component';
import { UserAssignmentComponent } from './pages/user/user-assignment/user-assignment.component';
import { UserAssignComponent } from './pages/user/user-assign/user-assign.component';
import { UserLeaveRequestComponent } from './pages/user/user-leave-request/user-leave-request.component';
import { UserDashboardComponent } from './pages/user/user-dashboard/user-dashboard.component';
import { UserProfileComponent } from './pages/user/user-profile/user-profile.component';
import { UserSalaryComponent } from './pages/user/user-salary/user-salary.component';
import { AttendanceComponent } from './pages/user/attendance/attendance.component';
import { AddTaskComponent } from './pages/user/add-task/add-task.component';
import { TaskComponent } from './pages/user/task/task.component';
import { UserMeetingComponent } from './pages/user/user-meeting/user-meeting.component';
import { OnuserEndLeaveComponent } from './pages/user/onuser-end-leave/onuser-end-leave.component';
import { UpdateTaskComponent } from './pages/user/update-task/update-task.component';
import { UpdateAssignmentComponent } from './pages/user/update-assignment/update-assignment.component';
import { UserTotalSalaryComponent } from './pages/user/user-total-salary/user-total-salary.component';
import { ForgetPasswordUserComponent } from './pages/user/forget-password-user/forget-password-user.component';
import { UserDocumentComponent } from './pages/user/user-document/user-document.component';
import { GraphEventsComponent } from './pages/user/graph-events/graph-events.component';
import { RatePerformanceComponent } from './pages/user/rate-performance/rate-performance.component';
import { StarRatingPipe } from './pages/user/star-rating.pipe';
import { ReviewComponent } from './pages/user/review/review.component';
import { DeveloperComponent } from './pages/user/developer/developer.component';
import { UserWelcomeComponent } from './pages/user/user-welcome/user-welcome.component';
import { FeedbackComponent } from './pages/user/feedback/feedback.component';
import { GoogleMapComponent } from './pages/user/google-map/google-map.component';
import { MarkAttendanceComponent } from './pages/user/mark-attendance/mark-attendance.component';
import { UserAnnouncementComponent } from './pages/user/user-announcement/user-announcement.component';
import { ShowUserChatComponent } from './pages/user/show-user-chat/show-user-chat.component';
import { SidebarComponent as UserSidebar } from './pages/user/sidebar/sidebar.component';
import { ShowuserTaskComponent } from './pages/admin/showuser-task/showuser-task.component';
import { AddCommentComponent } from './pages/admin/add-comment/add-comment.component';
import { DialogLeaveComponent } from './pages/admin/dialog-leave/dialog-leave.component';
import { CalenderComponent } from './pages/calender/calender.component';
import { ShowAllApprovedLeaveComponent } from './pages/admin/show-all-approved-leave/show-all-approved-leave.component';
import { EmailComponent } from './pages/admin/email/email.component';
import { MatMenuModule } from '@angular/material/menu';
import { MeetingsComponent } from './pages/admin/meetings/meetings.component';
import { SaveCandidateComponent } from './pages/admin/save-candidate/save-candidate.component';
import { ShowMeetingsComponent } from './pages/admin/show-meetings/show-meetings.component';
import { UpdateMeetingComponent } from './pages/admin/update-meeting/update-meeting.component';
import { AllCandidateComponent } from './pages/admin/all-candidate/all-candidate.component';
import { UpdateCandidateComponent } from './pages/admin/update-candidate/update-candidate.component';
import { CalendarInviteComponent } from './pages/admin/calendar-invite/calendar-invite.component';
import { UserTaskComponent } from './pages/admin/user-task/user-task.component';
import { AboutDeveloperComponent } from './pages/admin/about-developer/about-developer.component';
import { PreviousTaskComponent } from './pages/admin/previous-task/previous-task.component';
// import { AdminAssignmentComponent } from './pages/admin/admin-assignment/admin-assignment.component';
// import { AdminCommentAssignmentComponent } from './pages/admin/admin-comment-assignment/admin-comment-assignment.component';
// import { PreviousAssignmentComponent } from './pages/admin/previous-assignment/previous-assignment.component';
import { SingleAssignmentComponent } from './pages/admin/single-assignment/single-assignment.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GenericListFilterModule } from 'generic-list-filter';
import { EventModelComponent } from './pages/admin/event-model/event-model.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HRAttendanceComponent } from './pages/admin/hrattendance/hrattendance.component';
import { AgmCoreModule } from '@agm/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from 'src/environments/environment';
import { AsyncPipe, DatePipe } from '@angular/common';
import { UserGoogleMapComponentComponent } from './pages/admin/user-google-map-component/user-google-map-component.component';
// FullCalendarModule.registerPlugins([dayGridPlugin]);
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TotalSalaryComponent } from './pages/admin/total-salary/total-salary.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SalesAttendanceComponent } from './pages/admin/sales-attendance/sales-attendance.component';
import { TimestampFormatPipe } from './timestamp-format.pipe';
import { EventChartComponent } from './pages/admin/event-chart/event-chart.component';
import { ForgetPasswordAdminComponent } from './pages/admin/forget-password-admin/forget-password-admin.component';
import { AdminDocumentShowComponent } from './pages/admin/admin-document-show/admin-document-show.component';
import { LightboxModule } from 'ngx-lightbox';
import { UserRatingComponent } from './pages/admin/user-rating/user-rating.component';
import { ShowUserReviewComponent } from './pages/admin/show-user-review/show-user-review.component';
import { ShowUserRatingsComponent } from './pages/admin/show-user-ratings/show-user-ratings.component';
import { ReviewGraphComponent } from './pages/admin/review-graph/review-graph.component';
import { SidebarService } from '../app/services/sidebar.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth'
import { NotificationService } from './MyService/notification.service';
import { AssignTaskComponent } from './pages/admin/assign-task/assign-task.component';
import { NewComponent } from './pages/admin/new/new.component';
import { UserAssignmentComponent as ManagerUserAssignmentComponent } from './pages/manager/user-assignment/user-assignment.component';
import { UserAssignComponent as ManagerUserAssignComponent} from './pages/manager/user-assign/user-assign.component';
import { UserLeaveRequestComponent as ManagerUserLeaveRequestComponent} from './pages/manager/user-leave-request/user-leave-request.component';
// import { UserDashboardComponent as ManagerUserDashboardComponent} from './pages/manager/manager-dashboard/manager-dashboard.component';
import { UserProfileComponent as ManagerUserProfileComponent } from './pages/manager/user-profile/user-profile.component';
import { UserSalaryComponent as ManagerUserSalaryComponent } from './pages/manager/user-salary/user-salary.component';
import { AttendanceComponent as ManagerAttendanceComponent} from './pages/manager/attendance/attendance.component';
import { AddTaskComponent  as ManagerAddTaskComponent} from './pages/manager/add-task/add-task.component';
import { TaskComponent as ManagerTaskComponent } from './pages/manager/task/task.component';
import { UserMeetingComponent as ManagerUserMeetingComponent } from './pages/manager/user-meeting/user-meeting.component';
import { OnuserEndLeaveComponent as ManagerOnuserEndLeaveComponent} from './pages/manager/onuser-end-leave/onuser-end-leave.component';
import { UpdateTaskComponent as ManagerUpdateTaskComponent } from './pages/manager/update-task/update-task.component';
import { UpdateAssignmentComponent as ManagerUpdateAssignmentComponent } from './pages/manager/update-assignment/update-assignment.component';
import { UserTotalSalaryComponent  as ManagerUserTotalSalaryComponent} from './pages/manager/user-total-salary/user-total-salary.component';
import { ForgetPasswordUserComponent as ManagerForgetPasswordUserComponent } from './pages/manager/forget-password-user/forget-password-user.component';
import { UserDocumentComponent as ManagerUserDocumentComponent} from './pages/manager/user-document/user-document.component';
import { GraphEventsComponent as ManagerGraphEventsComponent} from './pages/manager/graph-events/graph-events.component';
import { RatePerformanceComponent as ManagerRatePerformanceComponent} from './pages/manager/rate-performance/rate-performance.component';
import { ReviewComponent as ManagerReviewComponent} from './pages/manager/review/review.component';
import { DeveloperComponent  as ManagerDeveloperComponent} from './pages/manager/developer/developer.component';
import { UserWelcomeComponent  as ManagerUserWelcomeComponent} from './pages/manager/user-welcome/user-welcome.component';
import { FeedbackComponent as ManagerFeedbackComponent } from './pages/manager/feedback/feedback.component';
import { GoogleMapComponent as ManagerGoogleMapComponent } from './pages/manager/google-map/google-map.component';
import { MarkAttendanceComponent as ManagerMarkAttendanceComponent } from './pages/manager/mark-attendance/mark-attendance.component';
import { UserAnnouncementComponent  as ManagerUserAnnouncementComponent } from './pages/manager/user-announcement/user-announcement.component';
import { ShowUserChatComponent as ManagerShowUserChatComponent } from './pages/manager/show-user-chat/show-user-chat.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatPaginatorIntl } from '@angular/material/paginator';


// import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
// import * as cloudinaryCore from 'cloudinary-core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AdminVerifiedComponent } from './pages/admin/admin-verified/admin-verified.component';
import { NewAnnouncementComponent } from './pages/admin/new-announcement/new-announcement.component';
import { SalaryCycleComponent } from './pages/admin/salary-cycle/salary-cycle.component';
import { AdminLeaveApplyComponent } from './pages/admin/admin-leave-apply/admin-leave-apply.component';
import { AdminAddTaskComponent } from './pages/admin/admin-add-task/admin-add-task.component';
import { LeaveModalComponent } from './pages/admin/leave-modal/leave-modal.component';
import { CustomChatComponent } from './pages/admin/custom-chat/custom-chat.component';
import { ShowChatComponent } from './pages/admin/show-chat/show-chat.component';
import { MessagesComponent } from './pages/admin/messages/messages.component';
import { ManagerDashboardComponent } from './pages/manager/manager-dashboard/manager-dashboard.component';
import { TaskStatisticsComponent } from './pages/manager/task-statistics/task-statistics.component';
import { EmployeeStatusComponent } from './pages/manager/employee-status/employee-status.component';
import { EmployeeTaskComponent } from './pages/manager/employee-task/employee-task.component';
import { AddMeetingComponent } from './pages/manager/add-meeting/add-meeting.component';
import { AddMessageComponent } from './pages/manager/add-message/add-message.component';
import { SharedModule } from './shared/shared.module';
import { ApproveLeaveComponent } from './pages/manager/approve-leave/approve-leave.component';
import { LeaveApproveComponent } from './pages/manager/leave-approve/leave-approve.component';
// import { AssignTaskDetailsComponent } from './pages/admin/assign-task-details/assign-task-details.component';
import { RatingViewComponent } from './pages/user/rating-view/rating-view.component';
import { GrowthComponent } from './pages/user/growth/growth.component';
import { ReportAttendanceMeetingComponent } from './pages/user/report-attendance-meeting/report-attendance-meeting.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiringCandidateComponent } from './pages/admin/hiring-candidate/hiring-candidate.component';
import { CandidatesUpdateComponent } from './pages/admin/candidates-update/candidates-update.component';
import { CandidateModalComponent } from './pages/admin/candidate-modal/candidate-modal.component';
import { TimeAgoPipe } from './time-ago.pipe';
import { CandidateSaveComponent } from './pages/admin/candidate-save/candidate-save.component';
import { ReviewFirstComponent } from './pages/admin/review-first/review-first.component';
import { ReviewSecondComponent } from './pages/admin/review-second/review-second.component';
import { ReviewThirdComponent } from './pages/admin/review-third/review-third.component';
import { UpdateStatusModalComponent } from './pages/admin/update-status-modal/update-status-modal.component';
import { TruncateWordsPipe } from './truncate-words.pipe';
import { ShowEmailPopupComponent } from './pages/admin/show-email-popup/show-email-popup.component';
import { ProjectsComponent } from './pages/admin/projects/projects.component';
import { ProjectDashboardComponent } from './pages/manager/project-dashboard/project-dashboard.component';
import { ProjectAssignModalComponent } from './pages/manager/project-assign-modal/project-assign-modal.component';
import { AddProjectComponent } from './pages/manager/add-project/add-project.component';
import { DetailsProjectComponent } from './pages/manager/details-project/details-project.component';
import { DetailAssignTaskComponent } from './pages/manager/detail-assign-task/detail-assign-task.component';
import { ManagerEmailComponent } from './pages/manager/manager-email/manager-email.component';
import { SubtaskAssignmentComponent } from './pages/manager/subtask-assignment/subtask-assignment.component';
import { DetailModalComponent } from './pages/manager/detail-modal/detail-modal.component';
import { AddNotesComponent } from './pages/manager/add-notes/add-notes.component';
import { AddStoryComponent } from './pages/manager/add-story/add-story.component';
import { UserAssignmentDetailsComponent } from './pages/user/user-assignment-details/user-assignment-details.component';
import { SubCheckerComponent } from './pages/user/sub-checker/sub-checker.component';
import { AddSubtaskUserComponent } from './pages/user/add-subtask-user/add-subtask-user.component';
import { AddStagesComponent } from './pages/manager/add-stages/add-stages.component';
import { UpdateSubtaskComponent } from './pages/manager/update-subtask/update-subtask.component';
import { AdminDetailsProjectComponent } from './pages/admin/admin-details-project/admin-details-project.component';
import { DeductionComponent } from './pages/admin/deduction/deduction.component';
import { ManagerHringComponent } from './pages/manager/manager-hring/manager-hring.component';
import { ReplaceUnderscorePipe } from './replace-underscore.pipe';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import {DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ManagerGrowthComponent } from './pages/manager/manager-growth/manager-growth.component';
import { FirstReviewComponent } from './pages/manager/first-review/first-review.component';
import { SecondReviewComponent } from './pages/manager/second-review/second-review.component';
import { ThirdReviewComponent } from './pages/manager/third-review/third-review.component';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#ff4081',
  pbColor: '#ff4081',
  pbThickness: 6,
  text: 'Please wait..',
  textColor: '#ff4081',
  blur: 6
};
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    UserDashboardComponent,
    ProfileComponent,
    SidebarComponent,
    WelcomeComponent,
    UserSidebar,
    ForgetPasswordComponent,
    UpdateSalaryComponent,
    ShowEmployeeSalaryComponent,
    // EmployeeAttendanceComponent,
    AllEmployeeProfileComponent,
    AddSalaryComponent,
    AddUserProfileComponent,
    UpdateUserProfileComponent,
    SingleEmployeeAttendanceComponent,
    ShowLeaveRequestComponent,
    UserProfileComponent,
    UserSalaryComponent,
    AttendanceComponent,
    AddTaskComponent,
    TaskComponent,
    UserMeetingComponent,
    OnuserEndLeaveComponent,
    ShowuserTaskComponent,
    AddCommentComponent,
    DialogLeaveComponent,
    CalenderComponent,
    ShowAllApprovedLeaveComponent,
    EmailComponent,
    MeetingsComponent,
    SaveCandidateComponent,
    ShowMeetingsComponent,
    UpdateMeetingComponent,
    AllCandidateComponent,
    UpdateCandidateComponent,
    CalendarInviteComponent,
    UserTaskComponent,
    AboutDeveloperComponent,
    UserLeaveRequestComponent,
    UpdateTaskComponent,
    PreviousTaskComponent,
    UserAssignmentComponent,
    UserAssignComponent,
    // AdminAssignmentComponent,
    // AdminCommentAssignmentComponent,
    UpdateAssignmentComponent,
    // PreviousAssignmentComponent,
    SingleAssignmentComponent,
    EventModelComponent,
    HRAttendanceComponent,
    UserGoogleMapComponentComponent,
    TotalSalaryComponent,
    UserTotalSalaryComponent,
    SalesAttendanceComponent,
    TimestampFormatPipe,
    EventChartComponent,
    ForgetPasswordAdminComponent,
    ForgetPasswordUserComponent,
    UserPerformanceComponent,
    UserDocumentComponent,
    AdminDocumentShowComponent,
    GraphEventsComponent,
    RatePerformanceComponent,
    StarRatingPipe,
    UserRatingComponent,
    ShowUserReviewComponent,
    ShowUserRatingsComponent,
    ReviewGraphComponent,
    ReviewComponent,
    DeveloperComponent,
    AssignTaskComponent,
    UserWelcomeComponent,
    FeedbackComponent,
    NewComponent,
    GoogleMapComponent,
    UserAnnouncementComponent,
    AdminVerifiedComponent,
    MarkAttendanceComponent,
    NewAnnouncementComponent,
    SalaryCycleComponent,
    AdminLeaveApplyComponent,
    AdminAddTaskComponent,
    LeaveModalComponent,
    CustomChatComponent,
    ShowChatComponent,
    ShowUserChatComponent,
    MessagesComponent,
    ManagerDashboardComponent,
    ManagerUserAssignComponent,
    ManagerUserLeaveRequestComponent,
    ManagerUserProfileComponent,
    ManagerUserSalaryComponent,
    ManagerAttendanceComponent,
    ManagerAddTaskComponent,
    ManagerTaskComponent,
    ManagerUserMeetingComponent,
    ManagerOnuserEndLeaveComponent,
    ManagerUpdateTaskComponent,
    ManagerUpdateAssignmentComponent,
    ManagerUserTotalSalaryComponent,
    ManagerForgetPasswordUserComponent,
    ManagerUserDocumentComponent,
    ManagerGraphEventsComponent,
    ManagerRatePerformanceComponent,
    ManagerReviewComponent,
    ManagerDeveloperComponent,
    ManagerUserWelcomeComponent,
    ManagerFeedbackComponent,
    ManagerGoogleMapComponent,
    ManagerMarkAttendanceComponent,
    ManagerUserAnnouncementComponent,
    ManagerShowUserChatComponent,
    ManagerUserAssignmentComponent,
    TaskStatisticsComponent,
    EmployeeStatusComponent,
    EmployeeTaskComponent,
    AddMeetingComponent,
    AddMessageComponent,
    ApproveLeaveComponent,
    LeaveApproveComponent,
    // AssignTaskDetailsComponent,
    RatingViewComponent,
    GrowthComponent,
    ReportAttendanceMeetingComponent,
    HiringCandidateComponent,
    CandidatesUpdateComponent,
    CandidateModalComponent,
    TimeAgoPipe,
    CandidateSaveComponent,
    ReviewFirstComponent,
    ReviewSecondComponent,
    ReviewThirdComponent,
    UpdateStatusModalComponent,
    TruncateWordsPipe,
    ShowEmailPopupComponent,
    ProjectsComponent,
    ProjectDashboardComponent,
    ProjectAssignModalComponent,
    AddProjectComponent,
    DetailsProjectComponent,
    DetailAssignTaskComponent,
    ManagerEmailComponent,
    SubtaskAssignmentComponent,
    DetailModalComponent,
    AddNotesComponent,
    AddStoryComponent,
    UserAssignmentDetailsComponent,
    SubCheckerComponent,
    AddSubtaskUserComponent,
    AddStagesComponent,
    UpdateSubtaskComponent,
    AdminDetailsProjectComponent,
    DeductionComponent,
    ManagerHringComponent,
    ReplaceUnderscorePipe,
    ManagerGrowthComponent,
    FirstReviewComponent,
    SecondReviewComponent,
    ThirdReviewComponent
    
  ],
  imports: [
    NgxChartsModule,
    BrowserModule,
    MatMenuModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatSlideToggleModule,
    MatSelectModule,
    CKEditorModule,
    CalendarModule,
    MatProgressSpinnerModule,
    NgxUiLoaderModule,
    BrowserModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    MatIconModule,
    ReactiveFormsModule,
    GenericListFilterModule,
    MatDialogModule,
    MatDatepickerModule,
    LightboxModule,
    NgxDropzoneModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    MatNativeDateModule,
    MatPaginatorModule,
    SharedModule,
    AngularEditorModule,
    DateRangePickerModule,
    NgxDaterangepickerMd.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCX_2By8eOjZ-NlHfPfKnid4BmZJaoFDyY',
      // libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a
      premium customer, in which case you can
      use clientId
      */
    }),
   
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
         FontAwesomeModule,

    // CloudinaryModule.forRoot(cloudinaryCore, {
    //   cloud_name: 'dlknklghr', // Replace with your Cloudinary cloud name
    // } as CloudinaryConfiguration),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers:  [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntl },
    // {provide: CloudinaryConfiguration,useValue: CloudinaryConfiguration},
    SidebarService, // <-- Provide SidebarService here
    authInterceptorProviders,
    DatePipe,
    NotificationService,
    AsyncPipe

  ],
  bootstrap: [AppComponent],
  

})

export class AppModule {}
