import { MainService } from 'src/app/MyService/main.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user-profile',
  templateUrl: './add-user-profile.component.html',
  styleUrls: ['./add-user-profile.component.css'],
})
export class AddUserProfileComponent implements OnInit {
  constructor(
    private snack: MatSnackBar,
    private mainService: MainService,
    private router: Router
  ) {}

  roleString: string;
  roles: any[];
  selectedLocation: any = null;
  file: any;
  folderName: any;
  locations:any;
  public user = {
    locationId: null,
    userName: '',
    password: '',
    firstName: '',
    dob: '',
    gender: '',
    lastName: '',
    email: '',
    phone: '',
    permanentAddress: '',
    currentAddress: '',
    designation: '',
    department: '',
    profileImage: '',
    roles: [] as string[],
  };

 

  ngOnInit(): void {
    this.mainService.getRoles().subscribe((res: any) => {
      this.roles = res;
    });
   this.locationList();
  // this.checkingCity();
  }
  locationList(){
    this.mainService.getAllLocation().subscribe((res:any)=>
      {
      this.locations = res;
      console.log(this.locations ,"location",JSON.stringify(res));
    })
    
  }

  checkingCity(event: any) {
    console.log('Selected Location ID:', event.value);
    this.user.locationId = event.value;  // ✅ Correctly setting locationId
  }
  formSubmit() {
    if (!this.user.locationId) {
      this.snack.open('Location is required!', '', { duration: 3000 });
      return;
    }
    if (this.user.userName == '' || this.user.userName == null) {
      this.snack.open('Username is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.password == '' || this.user.password == null) {
      this.snack.open('Password is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.firstName == '' || this.user.firstName == null) {
      this.snack.open('First name is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.lastName == '' || this.user.lastName == null) {
      this.snack.open('Last name is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.email == '' || this.user.email == null) {
      this.snack.open('Email is required!', '', {
        duration: 3000,
      });
      return;
    }

    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (!emailRegex.test(this.user.email)) {
      this.snack.open('Invalid email format!', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.phone == '' || this.user.phone == null) {
      this.snack.open('Phone is required!', '', {
        duration: 3000,
      });
      return;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(this.user.phone)) {
      this.snack.open('Invalid phone number', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.permanentAddress == '' || this.user.permanentAddress == null) {
      this.snack.open('Permanent Address is required!', '', {
        duration: 3000,
      });
      return;
    }

    if (this.user.currentAddress == '' || this.user.currentAddress == null) {
      this.snack.open('Current Address is required !! ', '', {
        duration: 3000,
      });
      return;
    }

    if (!this.user.roles || this.user.roles.length === 0) {
      this.snack.open('Role is required!', '', {
        duration: 3000,
      });
      console.log('User Roles:', this.user.roles);
      return;
    }

    if (this.file) {
      const folderName = 'profilePhotos';
      this.mainService.uploadFile(this.file, folderName).subscribe(
        (cloudinaryResponse: any) => {
          const secureUrl = cloudinaryResponse.message;
          this.user.profileImage = secureUrl;
          this.saveUserProfile();
        },
        (error) => {
          console.error('Error uploading file to Cloudinary:', error);
          Swal.fire('Error', ' ' + error.error.message, 'error');
        }
      );
    } else {
      this.saveUserProfile();
    }
  }

  saveUserProfile() {
    this.mainService.postUserData(this.user).subscribe(
      (data: any) => {
        Swal.fire('Successfully done !!', 'User id is ' + data.id, 'success');
        this.router.navigate(['admin/add-salary'], {
          queryParams: { id: data.id },
        });
      },
      (error) => {
        console.error('Error posting user data:', error);
        Swal.fire('Error', ' ' + error.error.message, 'error');
      }
    );
  }

  uploadFiles(event: any): void {
    this.file = event?.target?.files[0];

    if (!this.file) {
      console.log('No file selected.');
      alert('Please select a profile image');
      return;
    }

    // console.log('File selected:', this.file);
  }

  onChangeFileField(event: any) {
    const fileInput = event.target;
    const file = fileInput.files[0];

    if (file) {
      const fileSize = file.size;
      const maxSize = 1024 * 1024;

      if (fileSize > maxSize) {
        alert("File size exceeds 1 MB limit. Please choose a smaller file.");
        fileInput.value = '';
        return;
      }

      this.file = file;
      this.user.profileImage = this.file.name;
      // console.log('File name changed:', this.user.profileImage);
    }
  }

  getRoleString(role: any) {
    if (!this.user.roles.includes(role)) {
      this.user.roles.push(role);
    }
    this.roleString = role;
    // console.log('Role selected:', role);
  }
}
