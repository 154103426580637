<mat-dialog-content>
    <!-- <div class="modal-overlay" (click)="closeModal()"></div> -->
 
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <h3 style="color:black;font-weight: 600;">Take A Break</h3>
      <form [formGroup]="breakForm" (ngSubmit)="reasonForm()">
        <div class="breakform check-time break form-group">
          <!-- <span>
            <i class="fa-solid fa-clock"></i>
          </span> -->
          <div class="breakform2">
            <label style="margin-bottom: 10px;">Reason: </label>
            <select formControlName="reason" class="select-form form-control">
              <option value="" disabled>Select Reason</option>
              <option *ngFor="let reason of reasons" [ngValue]="reason">
                {{ reason }}
              </option>
            </select>
          </div>
        </div>
        <div class="buttonBreak">
          <button
        [disabled]="breakForm.invalid"
        mat-raised-button
        type="submit"
        style="background-color: rgb(206 50 98); color: white;margin-top:20px"
      >
        Save
      </button>
        </div>
       
      </form>
    </div>
  </mat-dialog-content>
  