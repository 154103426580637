import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-project-assign-modal',
  templateUrl: './project-assign-modal.component.html',
  styleUrls: ['./project-assign-modal.component.css'],
})
export class ProjectAssignModalComponent implements OnInit {
  assignByID = localStorage.getItem('id');
  isSubmitting = false;
  userId: number = 0;
  projectId: string;
  files: File[] = [];
  data: any;

  public taskData = {
    id: 0,
    assignToId: 0,
    assignById: this.assignByID,
    subtaskName: '',
    subtaskDescription: '',
    taskDeadline: 0,
    fileurl: [],
    subtaskStatus: 'ASSIGNED',
    priorityLevel: 'LOW',
    priority: true,
    story: [],
  };

  constructor(
    private snack: MatSnackBar,
    private mainService: MainService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<ProjectAssignModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.projectId = dialogData.projectId;
  }

  ngOnInit(): void {
    this.userId = Number(this.route.snapshot.queryParams['id']) || 0;

    if (this.userId === 0) {
      Swal.fire('Error', 'No user selected. Please select a user.', 'error');
      return;
    }

    this.taskData.assignToId = this.userId;

    this.mainService.managerAlllist(this.assignByID, true).subscribe(
      (res: any) => {
        this.data = res;
      },
      (error) => {
        Swal.fire('Error', 'Failed to fetch manager list', 'error');
      }
    );
  }

  // Handles file selection and validation
  onSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const maxFileSize = 300 * 1024; // 300 KB limit
      Array.from(input.files).forEach((file) => {
        if (file.size > maxFileSize) {
          Swal.fire('Error', `File "${file.name}" exceeds 300 KB limit.`, 'error');
        } else if (file.type === 'application/pdf') {
          Swal.fire('Error', `File "${file.name}" is a PDF and cannot be uploaded.`, 'error');
        } else {
          this.files.push(file);
        }
      });
    }
  }

  // Remove file from the list
  removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  // Submits the form
  formSubmit(): void {
    if (!this.taskData.subtaskName && this.files.length === 0) {
      Swal.fire('Error', 'Please enter a task name or upload at least one file.', 'error');
      return;
    }

    if (this.taskData.taskDeadline) {
      this.taskData.taskDeadline = new Date(this.taskData.taskDeadline).getTime();
    }

    this.isSubmitting = true;

    if (this.files.length > 0) {
      this.uploadFiles();
    } else {
      this.saveTaskData();
    }
  }

  // Uploads files asynchronously and updates task data
  uploadFiles(): void {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    this.files.forEach((file) => {
      const folderName = 'subtasksDocs';
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res) => {
          documentsArray.push(res.message);

          if (++processedFiles === this.files.length) {
            this.taskData.fileurl = documentsArray;
            this.saveTaskData();
          }
        },
        (error) => {
          console.error('File upload error:', error);
          Swal.fire('Error', `File upload failed for "${file.name}".`, 'error');
          this.isSubmitting = false;
        }
      );
    });
  }

  // Saves the task data
  saveTaskData(): void {
    this.mainService.getSubtaskByAssignment(this.userId, this.taskData).subscribe(
      (response) => {
        Swal.fire({
          title: 'Success',
          text: 'Task saved successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          this.router.navigate(['/manager-dashboard/details-project'], {
            queryParams: { id: this.projectId },
          });
          this.closeModal();
        });
        this.isSubmitting = false;
      },
      (error) => {
        console.error('Task save error:', error);
        Swal.fire('Error', 'Failed to save task. Please try again.', 'error');
        this.isSubmitting = false;
      }
    );
  }

  // Closes the modal
  closeModal(): void {
    this.dialogRef.close();
  }
}
