<h4>Add Employee</h4>
<div class="container">
<div class="row">
<div class="col-md-12">
  <mat-card >
    <form (ngSubmit)="formSubmit()" enctype="multipart/form-data">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input
              type="number"
              required
              [(ngModel)]="user.userName"
              name="userName"
              matInput
              placeholder="Enter  User Number"
            />
            <mat-hint>Username must be unique !!</mat-hint>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>User Password</mat-label>
            <input
              required
              [(ngModel)]="user.password"
              name="password"
              type="password"
              matInput
              placeholder="Enter Password"
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>First Name</mat-label>
            <input
              required
              [(ngModel)]="user.firstName"
              name="firstName"
              matInput
              placeholder="Enter First Name"
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input
              required
              [(ngModel)]="user.lastName"
              name="lastName"
              matInput
              placeholder="Enter Last Name"
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email Address</mat-label>
            <input
              required
              [(ngModel)]="user.email"
              name="email"
              type="text"
              matInput
              placeholder="Enter Email Address"
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Alternative Mobile Number</mat-label>
            <input
              required
              [(ngModel)]="user.phone"
              name="phone"
              type="number"
              matInput
              placeholder="Enter here"
            />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Current Address</mat-label>
            <input
              required
              [(ngModel)]="user.currentAddress"
              name="currentAddress"
              type="text"
              matInput
              placeholder="Enter here"
            />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Permanent Address</mat-label>
            <input
              required
              [(ngModel)]="user.permanentAddress"
              name="alter"
              type="text"
              matInput
              placeholder="Enter here"
            />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Date Of Birth</mat-label>
            <input
              required
              [(ngModel)]="user.dob"
              name="dob"
              type="date"
              matInput
              placeholder="Enter Date of Birth"
            />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Gender</mat-label>
            <mat-select
              [(ngModel)]="user.gender"
              name="gender" style="color:black"
              required 
            >
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Department</mat-label>
            <mat-select
              [(ngModel)]="user.department"
              name="department" style="color:black"
              required
            >
              <mat-option value="Management">Management</mat-option>
              <mat-option value="Hr">Human Resource</mat-option>
              <mat-option value="Finance">Finance</mat-option>
              <mat-option value="Sales">Sales</mat-option>
              <mat-option value="Marketing">Digital Marketing</mat-option>
              <mat-option value="IT & Development"
                >IT & Development</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            appearance="outline"
            *ngIf="user.department === 'Management'"
          >
            <mat-label>Designation</mat-label>
            <mat-select
              [(ngModel)]="user.designation"
              name="role"
              required
            >
              
              <mat-option value="General Manager"
                >General Manager</mat-option
              >
              <mat-option value="Administrative Officer"
                >Administrative Officer</mat-option
              >
              <mat-option value="Chief Executive Officer(CEO)"
                >Chief Executive Officer (CEO)</mat-option
              >
              <mat-option value="Strategic Lead"
                >Strategic Lead</mat-option
              >
              <mat-option value="Chief Operating Officer(COO)"
              >Chief Operating Officer (COO)</mat-option
            >
            <mat-option value="Chief Financial Officer(CFO)"
            >Chief Financial Officer (CFO)</mat-option
          >
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            appearance="outline"
            *ngIf="user.department === 'Hr'"
          >
            <mat-label>Designation</mat-label>
            <mat-select
              [(ngModel)]="user.designation"
              name="role"
              required
            >
              <mat-option value="HR Executive"
                >HR Executive</mat-option
              >
              <mat-option value="HR Manager">HR Manager</mat-option>
              <mat-option value="Recruiter">Recruiter</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            appearance="outline"
            *ngIf="user.department === 'Finance'"
          >
            <mat-label>Designation</mat-label>
            <mat-select
              [(ngModel)]="user.designation"
              name="role"
              required
            >
              <mat-option value="Accountant"
                >Accountant</mat-option
              >
              <mat-option value="Finance Manager"
                >Finance Manager</mat-option
              >
              <mat-option value="Account Executive"
                >Account Executive</mat-option
              >
            </mat-select>
            <mat-hint>Choose Role</mat-hint>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            appearance="outline"
            *ngIf="user.department === 'Sales'"
          >
            <mat-label>Designation</mat-label>
            <mat-select
              [(ngModel)]="user.designation"
              name="role"
              required
            >
              <mat-option value="Sales Executive"
                >Sales Executive</mat-option
              >
              <mat-option value="Sales Manager"
                >Sales Manager</mat-option
              >
              <mat-option value="Senior Sales Manager"
                >Senior Sales Manager</mat-option
              >
              <mat-option value="P&L Manager">P&L Manager</mat-option>
              <mat-option value="General Manager"
                >General Manager</mat-option
              >
              <mat-option value="Customer Relationship Manager(CRM)"
                >Customer Relationship Manager(CRM)</mat-option
              >
              <mat-option value="Real Estate Aspirant "
                >Real Estate Aspirant </mat-option
              >
              <mat-option value="Customer Care Representative "
                >Customer Care Representative </mat-option
              >
              <mat-option value="Portfolio Manager "
                >Portfolio Manager </mat-option
              >
              <mat-option value="Sales Head"
                >Sales Head</mat-option
              >
              
            </mat-select>
            <mat-hint>Choose Role</mat-hint>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            appearance="outline"
            *ngIf="user.department === 'Marketing'"
          >
            <mat-label>Designation</mat-label>
            <mat-select
              [(ngModel)]="user.designation"
              name="role"
              required
            >
              <mat-option value="Digital Marketing Manager"
                >Digital Marketing Manager</mat-option
              >
              <mat-option value="SEO Executive"
                >SEO Executive</mat-option
              >
              <mat-option value="Search Engine Optimization(SEO)"
                >Search Engine Optimization(SEO)</mat-option
              > 
              <mat-option value="Intern"
              >Intern</mat-option
            >
              
              <mat-option value="Campaign Manager"
                >Campaign Manager</mat-option
              >
              <mat-option value="Content Writer"
                >Content Writer</mat-option
              >
              <mat-option value="Social Media Analyst"
                >Social Media Analyst</mat-option
              >
              <mat-option value="Marketing Specialist"
                >Marketing Specialist</mat-option
              >
              <mat-option value="Marketing Analyst"
                >Marketing Analyst</mat-option
              >
              <mat-option value="Public Relation Manager"
                >Public Relation Manager</mat-option
              >
              <mat-option value="Brand Manager"
                >Brand Manager</mat-option
              >
              <mat-option value="Graphic Designer"
                >Graphic Designer</mat-option
              >
              
            </mat-select>
            <mat-hint>Choose Role</mat-hint>
          </mat-form-field>

          <mat-form-field
            class="full-width"
            appearance="outline"
            *ngIf="user.department === 'IT & Development'"
          >
            <mat-label>Designation</mat-label>
            <mat-select
              [(ngModel)]="user.designation"
              name="role"
              required
            >
              <mat-option value="Software Development Engineer"
                >Software Development Engineer</mat-option
              >
              <mat-option value="Senior Web Developer"
                > Senior Web Developer</mat-option
              >
              <mat-option value="Junior Web Developer"
                >Junior Web Developer</mat-option
              >
              <mat-option value="Full Stack Developer"
                >Full Stack Developer</mat-option
              >
              <mat-option value="Senior Web Designer"
                >Senior Web Designer</mat-option
              >
              <mat-option value="Junior Web Designer"
                >Junior Web Designer</mat-option
              >
              <mat-option value="Graphic Designer"
                >Graphic Designer</mat-option
              >
              <mat-option value="Backend-Develper"
                >Backend-Develper</mat-option
              >
              <mat-option value="Frontend Developer"
                >Frontend Developer</mat-option
              >
              <mat-option value="Senior App Developer/ Android Developer"
                > Senior App Developer/ Android Developer</mat-option
              >
              <mat-option value="Junior App Developer/ Android Developer"
                > Junior App Developer/ Android Developer</mat-option
              >
              <mat-option value="Software Tester"
              > Software Tester</mat-option
            >
            <mat-option value="Data Entry">Data Entry</mat-option>
            <mat-option value="Automation Tester">Automation Tester</mat-option>
            <mat-option value="Data Cleaning and Validation">Data Cleaning and Validation</mat-option>
            <mat-option value="Data Management">Data Management</mat-option>
            <mat-option value="Data Operator">Data Operator</mat-option>
            <mat-option value="Data Migration">Data Migration</mat-option>
            </mat-select>
            <mat-hint>Choose Role</mat-hint>
          </mat-form-field>
         

              <div class="form-group">
                <mat-label>Role</mat-label>
              <select
              style=" width: 100%;  height: 50px; margin-top: 3px; border: 1px solid #e0e0e0; border-radius: 5px; color: #837676; "
              required
              name="role"
              [(ngModel)]="roleString"
              (change)="getRoleString($event.target.value)"
              >
              <option>Select Role</option>
              <option *ngFor="let role of roles" [value]="role.roleName">
              {{ role.roleName }}
              </option>
              </select>
              </div>

        <div class="container text-center">
          <input
            style="width: 106%; height: 50px; margin-top: 27px; border: 1px solid #e0e0e0; border-radius: 5px; margin-left: -14px; color: #837676;"
            type="file"
            id="profile"
            (change)="onChangeFileField($event)"
          />
        </div>
        </div>
      </div>
      <div class="container text-center">
        <button type="submit" mat-raised-button  color="accent" style="color: white">
          Submit
        </button>
      </div>
    </form>
  </mat-card>
</div>
</div>
</div>
