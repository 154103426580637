  <div class="container-fluid">
    <div class="row">
      <!-- Left side: Login form -->
      <div class="col-md-6 col-sm-12 d-flex align-items-center" style="background-color: black; height: 100vh;">
        <div class="w-100 p-4">
          <div class="text-center" (click)="redirectToAdmin()">
            <img
              src="../../../assets/hr-icon.png"
              style="height: 46px;"
              alt="logo"
            />
            <h1 class="mt-4 text-white" style="font-size: 16px; line-height: 19.2px; font-weight: 700;">
              Login to your Account
            </h1>
            <h3  style="color: rgba(255, 255, 255, 0.76); font-size: 14px; line-height: 19.2px;">
              Create your free account to search or filter through 300,000+ screens.<br />
              No credit card required.
            </h3>
          </div>

          <!-- Initial login form -->
          <form
            class="login-form mt-4 text-center"
            (ngSubmit)="formSubmit()"
            *ngIf="!forgotPasswordVisible && !otpSent"
          >
          <div class="mb-3">
            <input
              class="form-control custom-input mx-auto input"
              [(ngModel)]="loginData.userName"
              name="userName"
              required
              matInput
              placeholder="Enter your username"
              style="max-width: 300px;"
            />
          </div>
          <div class="mb-3 position-relative">
            <div class="input-group mx-auto" style="max-width: 300px;">
              <input
                class="form-control custom-input input"
                [(ngModel)]="loginData.password"
                name="password"
                [type]="showPassword ? 'text' : 'password'"
                required
                matInput
                placeholder="Enter Password"
              />
              <span class="input-group-text" (click)="togglePasswordVisibility()">
                <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </span>
            </div>
          </div>
          
            
            <div class="text-center">
              <button
                mat-raised-button
                color="primary"
                type="submit"
                class="btn btn-primary"
                style="width: 124px;"
              >
                Login
              </button>
              <button
                mat-raised-button
                color="primary"
                type="button"
                *ngIf="showForgetPasswordButton && !forgotPasswordVisible"
                (click)="toggleForgotPasswordForm()"
                class="btn btn-secondary ms-3"
                style="width: 139px;"
              >
                Forgot Password
              </button>
            </div>
          </form>

          <!-- Forgot Password form -->
          <div *ngIf="forgotPasswordVisible && !otpSent" class="forgot-password-form mt-4">
            <form (submit)="sendOTP()">
              <div class="form-field mb-3">
                <!-- <label class="text-white" for="email">Enter your email:</label><br /> -->
                <input
                  [(ngModel)]="email"
                  name="email"
                  type="email"
                  class="form-control custom-input mx-auto"
                  required
                  matInput
                  placeholder="Enter your email"
                  style="max-width: 300px;"
                />
              </div>
              <div class="text-center">
                <button
                  mat-raised-button
                  color="primary"
                  type="button"
                  class="btn btn-primary"
                  (click)="sendOTP()"
                >
                  Send OTP
                </button>
              </div>
            </form>
          </div>

          <!-- OTP input field and submit button -->
          <div *ngIf="otpSent" class="otp-form mt-4">
            <form (submit)="submitOTP()">
              <div class="form-field mb-3">
                <!-- <label class="text-white" for="otp">Enter OTP:</label><br /> -->
                <input
                  [(ngModel)]="otp"
                  name="otp"
                  type="text"
                  class="form-control custom-input mx-auto"
                  required
                  matInput
                  placeholder="Enter OTP"
                  style="max-width: 300px;"
                />
              </div>
              <div class="text-center">
                <button
                  mat-raised-button
                  color="primary"
                  type="submit"
                  class="btn btn-primary"
                  (click)="submitOTP()"
                >
                  Submit OTP
                </button>
              </div>
            </form>
          </div>

          <!-- Shared Section -->
          <div class="mt-4 text-center">
            <h3 style="color: rgba(255, 255, 255, 0.5); font-size: 12px; line-height: 16.8px;">
              By continuing, you agree to Virtual Intelligence Terms of <br> Service and Privacy Policy.
            </h3>
            <!-- <div class='terms'>
              <div>
                <span style="color: white;">Trusted by teams at</span>
              </div>
              <div class='developer_name'>
                <span>BHUTANI</span>
                <span>INVEST MANGO</span>
                <span>GO PROPIFY</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 d-none d-md-flex align-items-center" style="height: 100vh; padding: 0;">
        <img src="../../../assets/Frame 1171275750.png" alt="Hr Console Logo" class="img-fluid full-cover-image" />
      </div>
      
      
    </div>
  </div>
