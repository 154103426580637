import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { ProjectAssignModalComponent } from '../project-assign-modal/project-assign-modal.component';
import { AddNotesComponent } from '../add-notes/add-notes.component';
import { AddStoryComponent } from '../add-story/add-story.component';
import { Subtask } from '../../Models/Subtask';
import { AddStagesComponent } from '../add-stages/add-stages.component';
import { AddTaskComponent } from '../add-task/add-task.component';
import { UpdateSubtaskComponent } from '../update-subtask/update-subtask.component';

@Component({
  selector: 'app-detail-assign-task',
  templateUrl: './detail-assign-task.component.html',
  styleUrls: ['./detail-assign-task.component.css']
})
export class DetailAssignTaskComponent implements OnInit {
  assignment: any;
  totalElements: number = 0;
  status: string;
  assignToId: any;
  pageSize: number = 10;
  pageNumber: number = 0;
  startDate: any;
  endDate: any;
  assignmentId: any;
  isPriority: boolean;
  selectedTask: any = null;
  isTaskDetailsVisible = false;
  subtask: any;
  story: string[] = [];
  assignments: any[] = [];
data:any;
  constructor(private dialog: MatDialog, private mainService: MainService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.assignToId = params['id'];
      this.assignmentId = params['assignId'];
      this.fetchData();
    });
    // console.log('Assignment File URLs:', this.assignment.fileUrl);
  }

  fetchData(): void {
    if (this.assignmentId) {
      this.mainService.getAllAssignmentDetails(
        this.assignmentId,
        this.isPriority,
        this.status,
        this.assignToId,
        this.pageNumber,
        this.pageSize
      ).subscribe((res: any) => {
        this.assignment = res.content;
        this.subtask = res.content;
        this.totalElements = res.totalElements;
        console.log("API Response:", JSON.stringify(res));
        console.log("check" ,this.assignment.fileUrl)
      }, error => {
        console.error("Error fetching data:", error);
      });
    } else {
      console.warn("Assignment ID is not available.");
    }
  }

  openDialogStory(subtaskId: number): void {
    this.dialog.open(AddStoryComponent, {
      width: '500px',
      data: { 
        projectId: this.assignmentId,
        subtaskId: subtaskId  // Pass the assignment ID here
      }
    });
  }
  openStoryDialog(storyIndex: number, storyItem: string,subtaskId:number): void {
    console.log("assignmetnID"+subtaskId);
    const dialogRef = this.dialog.open(AddStoryComponent, {
      
      data: {
        projectId: this.assignmentId,
        story: storyItem,
        storyIndex: storyIndex,
        subtaskId: subtaskId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.story[storyIndex] = result.updatedStory;
      }
    });
  }
  // updateTask(subtaskId):void{
  //   console.log("subtaskId"+subtaskId);
  //   console.log("projectID "+ this.assignmentId)
  //    this.dialog.open(AddTaskComponent, {
  //     width: '800px',
  //     data: {
  //       projectId: this.assignmentId,
  //       subtaskId: subtaskId,
       
  //     }
  //   });
   
  // }
 
  toggleTaskDetails(taskId: any): void {
    this.isTaskDetailsVisible = !this.isTaskDetailsVisible;
    if (this.isTaskDetailsVisible) {
      this.selectedTask = this.assignment.find(task => task.id === taskId);
      this.story = this.selectedTask ? this.selectedTask.story : [];
    }
  }
  updateTask(subtaskId):void{
    console.log("subtaskId "+ subtaskId)
    this.dialog.open(UpdateSubtaskComponent,{
      width:"700px",
      height:"500px",
      data:{
        subtask : subtaskId,
        projectId :this.assignmentId
      }
    })
  }
  openDialog(): void {
    this.dialog.open(ProjectAssignModalComponent, {
      width: '800px',
    });
  }
  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }
  getFileIcon(file: string): string {
    const extension = file.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return '../../../../assets/icons/pdf.png';
      case 'xls':
      case 'xlsx':
        return '../../../../assets/icons/excel.png';
      case 'jpg':
        return '../../../../assets/task.png';
      case 'jpeg':
        return '../../../../assets/task.png';
      case 'png':
        return '../../../../assets/task.png';
      case 'gif':
        return '../../../../assets/task.png';
      default:
        return '../../../../assets/default.png';
    }
  }

  onDeleteTask(subtaskId: any): void {
    console.log("Deleting subtask with ID:", subtaskId);
    if (subtaskId) {
      this.mainService.deleteSubTask(subtaskId, this.assignmentId).subscribe(deleteRes => {
        Swal.fire("Task deleted successfully:", subtaskId);
        this.fetchData();
      }, error => {
        Swal.fire("Error deleting task:", error);
      });
    } else {
      console.error("Invalid subtask ID:", subtaskId);
    }
  }
}
