import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from 'src/app/MyService/main.service';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormControl, FormGroup ,Validators} from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-break-modal',
  templateUrl: './break-modal.component.html',
  styleUrls: ['./break-modal.component.css'],
})
export class BreakModalComponent implements OnInit {
  id: string;
  reasons = ['Lunch', 'Tea Break', 'Personal Work', 'Parcel or Bank'];
  breakForm = new FormGroup({
    reason: new FormControl('',Validators.required)
  })
  constructor(
    private mainService: MainService,
    public dialogRef: MatDialogRef<BreakModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = data.id;
  }

  ngOnInit(): void {
 
  }
  closeModal(): void {
    this.dialogRef.close();
    location.reload();
  }
  reasonForm(){
    if (this.breakForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'Please select a reason!',
      });
      return;
    }
    const requestData = {
      userId: this.id,  // Pass the user ID correctly
      reason: this.breakForm.value.reason,
    };
    this.mainService.addBreakTime(requestData).subscribe(
      (res: any) => {
        this.data = res;
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: this.data.message,
        });
        // Reload attendance data
      },
      (error) => {
        console.error('Error adding break:', error.error.message);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.error.message,
        });
      }
    );
  }
}
