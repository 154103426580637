<div class="container2">
    <div class="breadcrumb-container" style="margin-top: 10px;">
      <div class="breadcrumb-item" (click)="navigateToDashboard()">Assignment</div>
      <div class="breadcrumb-arrow">></div>
      <div class="breadcrumb-item" style="color:white">Task And Projects</div>
    </div>
    
    <div class="container">
      <div class="left-section">
        <div class="breadcrumb-container2">
          <div class="breadcrumb-item2" style="margin-top: -30px;">
            <div class="images">
              <img src="../../../../assets/icons/starting.png" alt="starting point"/>
              <img style="margin-left: 10px; margin-top: 4px;" src="../../../../assets/icons/connection.png" alt=""/>
            </div>
          </div>
          <div class="breadcrumb-arrow">
            <img src="../../../../assets/icons/starting1.png" alt="second"/>
          </div>
          <div class="breadcrumb-item2">Project Details</div>
          <div class="breadcrumb-arrow">
            <img src="../../../../assets/icons/connect.png" alt="connect"/>
          </div>
          <div class="breadcrumb-item2">
            <img src="../../../../assets/icons/sqaure.png" alt="square"/>
            <span style="margin-left: 5px;"> Tasks And Stories</span>
          </div>
        </div>
      </div>
      
      <div class="right-section" style="height: 500px; overflow-y: auto;">
        <h1 class="task">Task</h1>
        <div class="task-list">
            <div class="task-item" *ngFor="let assignment of assignments">
                <div class="task-item-left">
                  <div class="task-header" style="display: flex; justify-content: space-between; align-items: center;">
                    <div style="display: flex; align-items: center;">
                      <h3 style="margin: 0; text-transform: capitalize; cursor: pointer;" 
                          (click)="toggleTaskDetails(assignment.id)">
                        {{assignment.name}}
                      </h3>
                    </div>
                  </div>
                  <p style="display: flex;">
                    {{assignment.description}}
                    <ng-container *ngIf="assignment.fileUrl">
                      <div *ngFor="let url of assignment.fileUrl">
                    <a  [href]="url" target="_blank">
                      <img src="../../../../assets/attach-file.png" alt="add file"/>
                    </a>
                  </div>
                  </ng-container>
                  </p>
                  <div class="task-item-right">
                    <button class="" *ngIf="assignment.assignedById == assignToId" (click)="updateTask(assignment.id)">
                       <mat-icon>edit</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              
        </div>
  
        
        <div class="pagination-container">
            
            <mat-paginator 
              [length]="totalElements" 
              [pageSize]="pageSize" 
              [pageSizeOptions]="[10, 20, 50, 100]" 
              (page)="onPageChange($event)">
            </mat-paginator>
          </div>
          
      </div>
  
      <div class="task-details-container" *ngIf="isTaskDetailsVisible" [ngClass]="{'open': isTaskDetailsVisible}">
        <!-- <div class="todos-header">
          <div class="todos-icon">
            <img src="../../../../assets/icons/Category.png" alt="story description-section" /> <span style="  color: #cecece;
            font-size: 18px;
            text-decoration: underline;
            text-decoration-thickness: 1px;
            padding: 10px;
            text-underline-offset: 3px;">SubTask Details</span>
          </div>
          <div class="add-task-icon">
            <img src="../../../../assets/icons/Frame 73.png" alt="story description-section" (click)="openDialog()" />
          </div>
        </div> -->
        <div class="container" *ngIf="selectedTask" style="margin: 0 auto; max-width: 800px; padding: 20px; background: #282828; height: -webkit-fill-available;">
          <h1>
            Task Details 
            <span style="float: right; font-size: 10px; border: 1px solid grey; padding: 0px 9px 0px 9px; border-radius: 20px;">
              High Priority
            </span>
          </h1>
          
          <div class="task-details">
            <div class="new">
              <h3>Assigned Date</h3>
              <span class="date">
                <img src="../../../../assets/icons/calendar.png" alt="calendar"/>
                {{selectedTask.createdTime | date: 'd MMM yyyy'}}
              </span>
            </div>
          </div>
  
          <div class="task-details">
            <div class="new">
              <h3>Deadline Date</h3>
              <span class="date">
                <img src="../../../../assets/icons/calendar.png" alt="calendar"/>
                {{selectedTask.deadline | date: 'd MMM yyyy'}}
              </span>
            </div>
          </div>
  
          <div class="task-status">
            <h3>Assigned by:</h3>
            <div>{{selectedTask.assignedByName}}</div>
          </div>
  
          <div class="project-files">
            <h3>Project Files</h3>
            <div class="file" *ngFor="let file of selectedTask.files">
              <i [ngClass]="file.icon"></i> {{file.fileUrl}}
            </div>
          </div>
  
          <div class="task-status">
            <h3>Task Status</h3>
            <div class="status">{{selectedTask.status}}</div>
          </div>
  
          <div class="task-last-update">
            <h3>Task Last Update</h3>
            <div class="date">
              <img src="../../../../assets/icons/calendar.png" alt="calendar"/>
              {{selectedTask.updatedTime | date: 'd MMM yyyy'}}
            </div>
          </div>
  
          <div class="task-status">
            <h3>Story:</h3>
            <p>{{selectedTask.story}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  