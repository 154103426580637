<div class="full-page">
    <h4>Break</h4>
    <div class="header-row">
      <h5>Break List</h5>
      <div class="button-group">
        <button mat-button class="custom-button" (click)="openAddBreakModal(id)">
          <mat-icon>filter_list</mat-icon> Add Breaks
        </button>
        
        <button mat-button class="custom-button" (click)="toggleFilterForm()">
          <mat-icon>filter_list</mat-icon> Filter
        </button>
      </div>
    </div>
    
     <div class="filter-container">
    
      <div class="filter-form-wrapper" *ngIf="showFilterForm">
        <div class="filter-btn">
          <h2>Filter</h2>
          <button mat-icon-button (click)="toggleFilterForm()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <form #filterForm="ngForm" (submit)="applyFilter($event)" style="display: block;">
          <div class="form-group">
            <label for="dateRange">Select Date:</label><br />
            <div style="display: flex;">
              <input id="startDate" name="startDate" [(ngModel)]="startDate" type="date" class="form-control" style="margin-right: 10px;" />
              <input id="endDate" name="endDate" [(ngModel)]="endDate" type="date" class="form-control" />
            </div>
          </div>
          <div class="form-group"  *ngIf="role !== 'USER'">
            <label>Select Employee</label>
            <select id="userId" name="employeeId" [(ngModel)]="user_id"  class="selector">
              <option value="">Select Employee</option>
              <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.userName }} </option>
            </select>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
          </div>
        </form>
      </div>
    </div> 
    
    <div class="content-wrapper">
      <section class="Leads_manage leads_redesign">
        <div class="container2">
          <div class="scrollable-content">
            <div class="row">
              <div class="col-md-12">
                <div class="in_time_location">
                  <div class="table-responsive table-fixed table-bg">
                    <table class="table-hover table-striped text-center">
                      <thead>
                        <tr>
                          <th >Sn.</th>
                          <th >Name</th>
                          <th >Date</th>
                          <th >Reason</th>
                          <th >In Time</th>
                          <th >Out Time</th>
                          <th >Back To Work</th>
                        </tr>
                      </thead>
                      <tbody class="serialNumber">
                        <tr  *ngFor="let user of breakList" >
                          <!-- <td>{user.userId}</td> -->
                          <td>{{ user.id }}</td>
                          <td>{{ user.userName }}</td>
                          <td>{{ user.createdTime | date: 'dd/MM/yyyy' }}</td>  
                          <td>{{user.reason}}</td>
                          <td>{{ user.startTime | date: 'hh:mm a' }}</td>
                          <td>{{ user.endTime | date: 'hh:mm a' }}</td>
                          <td>
                            <mat-slide-toggle
                              class="btnBack"
                              [checked]="!!user.endTime"  
                              (change)="toggleBreakForm(user.id)"
                            >
                              Back to Work
                            </mat-slide-toggle>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <div class="pagination-container">
        <div class="pagination-info">
          Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
        </div>
        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 50, 100]" (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>
    </div>