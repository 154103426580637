<div class="content-wrapper full-page" style="background-color: #fff !important;border-radius: 30px;">
    <section class="Leads_manage leads_redesign">
      <div class="button-container">
        <h2>Mark Attendance</h2>
        <button class="punch-button" id="punchInButton" (click)="markAttendance()">Punch In</button>
        <button class="punch-button" id="punchOutButton" (click)="updateAttendance()">Punch Out</button>
      </div>
    
      <div class="map-container">
        <tbody>
          <tr>
            <td style="    height: 505px;
            width: 1343px;" >
              <!-- <agm-map class="custom-agm-map" [latitude]="28.5915776" [longitude]="77.3840254" [zoom]="15" [mapTypeControl]="true">
                <agm-marker [latitude]="28.5915776" [longitude]="77.3840254"></agm-marker>
              </agm-map> -->
              <agm-map class="custom-agm-map" [latitude]="latitude" [longitude]="longitude" [zoom]="15" [mapTypeControl]="true">
                <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
              </agm-map>
            </td>
          </tr>
        </tbody>
      </div>
    </section>
    </div>
    