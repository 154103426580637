import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-subtask-user',
  templateUrl: './add-subtask-user.component.html',
  styleUrls: ['./add-subtask-user.component.css']
})
export class AddSubtaskUserComponent implements OnInit {
  assignedById = localStorage.getItem('id');
  isSubmitting = false;
  employees: any[] = [];
  files: File[] = [];
  isUpdateMode: boolean = false;
  public updateUser = {
    subtaskName: '',
    subtaskDescription: '',
    taskDeadline: '',
    fileurl: [],
    subtaskStatus: '',
    priorityLevel: '',
  };

  constructor(
    private snack: MatSnackBar, 
    private mainService: MainService, 
    private router: Router,
    private dialogRef:MatDialogRef<AddSubtaskUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string, subtask: string }
  ) {}
 
  ngOnInit(): void {
    console.log( "subtask Id"+ this.data.subtask)
    console.log("tesing projectId" + this.data.projectId)
    if(this.data.subtask){
      this.isUpdateMode=true
   
    this.mainService.getAllSubtask(this.data.subtask).subscribe((res: any) => {
      this.updateUser = {
        ...this.updateUser,
        subtaskName: res.name,
        subtaskDescription: res.description,
        taskDeadline: new Date(res.deadline).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }),  
        fileurl: res.fileUrl,
        subtaskStatus: res.status,
        priorityLevel: res.priorityLevel,
      };
    });
  }
  }

  onSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {

      const maxFileSize = 5 * 1024 * 1024; // 300 KB limit
      Array.from(input.files).forEach((file) => {
        if (file.size > maxFileSize) {
          Swal.fire('Error', `File "${file.name}" exceeds 5 MB limit.`, 'error');
        } else if (file.type === 'application/pdf') {
          Swal.fire('Error', `File "${file.name}" is a PDF and cannot be uploaded.`, 'error');
        } else {
          this.files.push(file);
        }
      });
    }
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
  }
  formSubmit() {
    if (this.files.length === 0 && !this.updateUser.subtaskDescription) {
      Swal.fire('Error', 'Please upload a file or provide a task description.', 'error');
      return;
    }
    if (this.updateUser.taskDeadline) {
      this.updateUser.taskDeadline = new Date(this.updateUser.taskDeadline).getTime().toString();
    }

    this.isSubmitting = true;
    if (this.files.length > 0) {
      this.uploadFiles();
    } else {
      this.updateTask();
    }
  }

  // uploadFiles() {
  //   const formData = new FormData();
  //   formData.append('file', this.files[0]);
  //   this.mainService.uploadImage(formData).subscribe(
  //     (res: any) => {
  //       this.updateUser.fileurl = res.secure_url;
  //       this.updateTask();
  //     },
  //     (error) => {
  //       Swal.fire('Error', 'Failed to upload file.', 'error');
  //       this.isSubmitting = false;
  //     }
  //   );
  // }

  uploadFiles(): void {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    // if (this.selectedFiles.length === 0) {
    //   this.addTask();
    //   return;
    // }

    this.files.forEach((file) => {
      const data = new FormData();
      const folderName = 'taskDocs';
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res) => {
          const secureUrl = res.message;
          documentsArray.push(secureUrl);

          if (++processedFiles === this.files.length) {
            this.updateUser.fileurl = [...documentsArray]; // Use the first uploaded file's URL.
            this.updateTask();
          }
        },
        (error) => {
          console.error(error);
          this.isSubmitting = false;
        }
      );
    });
  }
  updateTask() {
    console.log("Assignment ID"+ this.data.projectId)
    console.log("this.data.subtask,"+ this.data.subtask)
    console.log("manger check"+this.updateUser)
    this.mainService.addStoryBySubtaskId(this.data.projectId ,this.data.subtask, this.updateUser).subscribe(
      (res) => {
        Swal.fire('Success', 'Task updated successfully.', 'success');
        this.router.navigate(['/user-dashboard/subtask-assignment-details?assignId=${projectId}']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to update task.', 'error');
        this.isSubmitting = false;
      }
    );
  }
  closeModal():void{
    this.dialogRef.close();
  }
}

