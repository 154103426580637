import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from 'src/app/MyService/main.service';

@Component({
  selector: 'app-metting-modal',
  templateUrl: './metting-modal.component.html',
  styleUrls: ['./metting-modal.component.css', '/../../../shared/all-style/add-meeting-style.component.css']
})
export class MettingModalComponent{

  meetingForm = new FormGroup({
    clientName: new FormControl('', Validators.required),
    clientEmail: new FormControl('', [Validators.required, Validators.email]),
    clientPhone: new FormControl('', Validators.required),
    clientAddress: new FormControl(''),
    channelPartnerName: new FormControl(''),
    feedback: new FormControl(''),
    projectDetails: new FormControl(''),
    source: new FormControl(''),
  });

  constructor(
    private mainService: MainService,
    public dialogRef: MatDialogRef<MettingModalComponent>
  ) { }


  formSubmit(): void {
    if (this.meetingForm.valid) {
      console.log('Form Submitted', this.meetingForm.value);

      // Call the service to submit the form data
      this.mainService.submitMeetingDetails(this.meetingForm.value).subscribe(
        (res) => {
          console.log('Response from server:', res);
          // Close the modal only after the form is successfully submitted
          this.dialogRef.close();
        },
        (error) => {
          console.error('Error submitting form:', error);
        }
      );
    } else {
      console.log('Form is invalid');
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
