

  <h2>Add Task</h2>
<mat-card-content>
  <div class="meetingContainer">
    <form (ngSubmit)="formSubmit()">
      
      <!-- <div class="form-group">
        <label for="employee" class="form-label">Select Employee</label>
        <select name="employee" id="employee" [(ngModel)]="employee" class="form-control" required>
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of data" [value]="employee.id">
            {{employee.userName}}
          </option>
        </select>
      </div> -->
      <label for="employee" style="color: white;">Select Employee:</label>
      <div class="form-field" style="background: rgb(49, 48, 48);">
        <select id="employee" name="employee" style="background-color: #2d2d2d;    border-radius: 6px; color: white;width: 100%; height: 39px; padding: 8px"  [(ngModel)]="employee" (change)="onEmployeeChange($event)">
          <option value="" disabled selected>Select Employee</option>
          <option *ngFor="let employee of data" [value]="employee.id">
            {{employee.userName}}
          </option>
        </select>
      </div>
      
     
      <div class="row" style=" margin-top:10px;">
        <div class="col">
          <label>DeadLine Date</label>
          <input type="date" id="deadlineDate" name="deadlineDate" class="form-control"  [(ngModel)]="user.deadDate"  >
        </div>
        <div class="col">
          <label>DeadLine Time</label>
          <input type="time" id="deadlineTime" name="deadlineTime" class="form-control"  [(ngModel)]="user.deadTime" >
        </div>
      </div>
      
      <!-- File Upload Section -->
      <!-- <div ngx-dropzone (change)="onSelect($event)" class="custom-dropzone">
        <ngx-dropzone-label>
          <mat-icon>cloud_upload</mat-icon><br>Drop Task Files
        </ngx-dropzone-label>
        <ngx-dropzone-image-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove($event)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
      </div> -->
      
      <mat-form-field class="w100" appearance="outline" >
        <mat-label style="color: white;">Task</mat-label>
        <textarea required autofocus type="text" name="Task" matInput placeholder="Enter here" [(ngModel)]="user.subject" style="color: white;" rows="8"></textarea>
      </mat-form-field>
      <!-- <div>
        <label>Upload File</label>
        <input type="file" name="taskFiles" (change)="onSelect($event)" />
      
      </div> -->
      <div>
        <label for="fileInput">Upload Files</label>
        <input
          type="file"
          id="fileInput"
          name="taskFiles"
          (change)="onSelect($event)"
          multiple
        />
        <div *ngFor="let file of selectedFiles; let i = index" class="file-item">
          <span>{{ file.name }} ({{ file.type }})</span>
          <button type="button" (click)="removeFile(i)">Remove</button>
        </div>
      </div>
      <!-- Submit Button and Spinner -->
      <div class="container text-center mt15">
        <button mat-raised-button color="accent">Save Task</button>
        <mat-spinner *ngIf="isSubmitting" diameter="30"></mat-spinner>
      </div>
      
    </form>
  </div>
</mat-card-content>
