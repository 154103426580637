<div class="sidebar"  [ngClass]="{ 'sidebar-open': isSidebarOpen, 'sidebar-closed': !isSidebarOpen }">
  <!-- [ngClass]="{'collapsed': !showMyContainer} " -->
  <div class="logo" style="display: flex;">
    <!-- <button (click)="toggleSidebar()" class="toggler-btn">
      <img src="../../../assets/menu2.png" style="width: 18px;"/> 
    </button> -->
    <!-- <a routerLink="/admin" class="navbar-brand" aria-label="HR Console Logo"> -->
      <!-- <img src="../../../assets/HR CONSOLE HORIZONTAL copy.png" alt="HR Console" style="height: 58px; border-radius: 10px;margin-left: -15px;" /> -->
      <!-- <span *ngIf="isLoggedIn">Hi, {{ user.firstName }} {{ user.lastName }}🖐️</span> -->
    <!-- </a> -->
    <!-- <button class="btn-close" *ngIf="collaspsed">toggler</button> -->
    <!-- <button (click)="showMyContainer=!showMyContainer">Show/Hide</button> -->
  
    
  </div>
  <div *ngIf="showMyContainer">
  <div class="menu" *ngIf="userRole === 'ADMIN'" >
  
    <div class="item">
      <a routerLink="/admin" class="menu-link">
        <i class="fas fa-th-large"></i>
        <span>Dashboard</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="/admin/all-employee-profile" class="menu-link">
        <i class="fas fa-users"></i>
        <span>Profiles</span>
      </a>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-user-plus"></i>
        <span>Interview <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
        <div class="sub-menu">
          <a routerLink="/admin/hiring-candidate" class="sub-item">
            <i class="fas fa-users"></i> Applicant Pool
          </a>
          <a routerLink="/admin/candidate-update" class="sub-item">
            <i class="fas fa-user-tie"></i> Candidate
          </a>
        </div>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-fingerprint"></i>
        <span>Presence <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu">
        <a routerLink="/admin/HrAttendance" class="sub-item">
          <i class="fas fa-fingerprint"></i> Attendance
        </a>
        <a routerLink="/admin/showLeave-Request" class="sub-item">
          <i class="fas fa-calendar-check"></i> Day Off Leave
        </a>
      </div>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-tasks"></i>
        <span>Assignment <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu">
        <a routerLink="/admin/project" class="sub-item">
          <i class="fas fa-tasks"></i> Assignment
        </a>
        <a routerLink="/admin/showuser-task" class="sub-item">
          <i class="fas fa-tasks"></i> Tasks
        </a>
      </div>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-comments"></i>
        <span>Notification<i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu" >
        <a routerLink="/admin/new-announcement" class="sub-item">
          <i class="fas fa-bullhorn"></i> Announcement
        </a>
        <a routerLink="/admin/email" class="sub-item" style="width: 100%;">
          <i class="fas fa-envelope"></i> Email
        </a>
        <a routerLink="/admin/show-chat" class="sub-item">
          <i class="fas fa-comment"></i> Message
        </a>
        
        <a routerLink="/admin/show-meetings" class="sub-item">
          <i class="fas fa-handshake"></i> Meeting
        </a>
      </div>
    </div>

    <div class="item">
      <a routerLink="/calender" class="menu-link">
        <i class="fas fa-calendar-alt"></i>
        <span>Calendar</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="/admin/show-user-review" class="menu-link">
        <i class="fas fa-chart-line"></i>
        <span>Performance</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="/admin/show-employee-salary" class="menu-link">
        <i class="fas fa-money-check-alt"></i>
        <span>Payroll</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="/admin/forget-password-admin" class="menu-link">
        <i class="fas fa-lock"></i>
        <span>Change Password</span>
      </a>
    </div>
    <div class="item" (click)="logout()">
      <span class="menu-link">
        <i class="fas fa-lock"></i>
        <span>Logout</span>
     </span>
    </div>
    
  </div>
</div>
<!-- manager -->


  <div class="menu" *ngIf="userRole === 'MANAGER'">
    <div class="item">
      <a routerLink="/manager-dashboard" class="menu-link">
        <i class="fas fa-th-large"></i>
        <span>Dashboard</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="hiring" class="menu-link">
        <i class="fas fa-users"></i>
        <span>Hiring</span>
      </a>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-fingerprint"></i>
        <span>Presence <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu">
        <a routerLink="attendance" class="sub-item">
          <i class="fas fa-fingerprint"></i> Attendance
        </a>
        <a routerLink="user-leave" class="sub-item">
          <i class="fas fa-calendar-check"></i> Day Off Leave
        </a>
      </div>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-tasks"></i>
        <span>Assignment <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu">
        <a routerLink="project-dashboard" class="sub-item">
          <i class="fas fa-tasks"></i> Assignment
        </a>
        <a routerLink="task" class="sub-item">
          <i class="fas fa-tasks"></i> Tasks
        </a>
      </div>
    </div>

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-comments"></i>
        <span>Notification <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu" >
       
        <a routerLink="email-manager" class="sub-item" style="width: 100%;">
          <i class="fas fa-envelope"></i> Email
        </a>
        <a routerLink="show-user-chat" class="sub-item">
          <i class="fas fa-comment"></i> Message
        </a>
        
        <a routerLink="user-meeting" class="sub-item">
          <i class="fas fa-handshake"></i> Meeting
        </a>
      </div>
    </div>

    <!-- <div class="item">
      <a routerLink="/admin/calendar" class="menu-link">
        <i class="fas fa-calendar-alt"></i>
        <span>Calendar</span>
      </a>
    </div> -->

    <!-- <div class="item">
      <a routerLink="user-review" class="menu-link">
        <i class="fas fa-chart-line"></i>
        <span>Performance</span>
      </a>
    </div> -->

    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-comments"></i>
        <span>Execution <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu" >
       
        <a routerLink="user-review" class="sub-item" style="width: 100%;">
          <i class="fas fa-envelope"></i> Performance
        </a>
        <a routerLink="user-document" class="sub-item">
          <i class="fas fa-comment"></i> Documentation
        </a>
        
        <a routerLink="feedback" class="sub-item">
          <i class="fas fa-handshake"></i> Feedback
        </a>
      </div>
      
    </div>



    <div class="item">
      <a routerLink="user-salary" class="menu-link">
        <i class="fas fa-money-check-alt"></i>
        <span>Payroll</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="forget-password-user" class="menu-link">
        <i class="fas fa-lock"></i>
        <span>Change Password</span>
      </a>
    </div>
    <div class="item" (click)="logout()">
      <span class="menu-link">
      <i class="fas fa-sign-out-alt"></i>
      <span>Logout</span>
    </span>
    
  </div>
  </div>


<!-- user -->


  <div class="menu" *ngIf="userRole === 'USER'">
    <div class="item">
      <a routerLink="/user-dashboard" class="menu-link">
        <i class="fas fa-th-large"></i>
        <span>Dashboard</span>
      </a>
    </div>


    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-fingerprint"></i>
        <span>Presence <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu">
        <a routerLink="attendance" class="sub-item">
          <i class="fas fa-fingerprint"></i> Attendance
        </a>
        <a routerLink="user-leave" class="sub-item">
          <i class="fas fa-calendar-check"></i> Day Off Leave
        </a>
      </div>
    </div>
   
    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-comments"></i>
        <span>Notification <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu" >
        <a routerLink="show-user-chat" class="sub-item">
          <i class="fas fa-comment"></i> Message
        </a>
        
        <a routerLink="user-meeting" class="sub-item">
          <i class="fas fa-handshake"></i> Meeting
        </a>
      </div>
    </div>


    <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-comments"></i>
        <span>Execution <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu" >
       
        <a routerLink="user-review" class="sub-item" style="width: 100%;">
          <i class="fas fa-envelope"></i> Performance
        </a>
        <a routerLink="user-document" class="sub-item">
          <i class="fas fa-comment"></i> Documentation
        </a>
        
        <a routerLink="feedback" class="sub-item">
          <i class="fas fa-handshake"></i> Feedback
        </a>
      </div>
      </div>
 <div class="item has-sub-menu">
      <a class="menu-link">
        <i class="fas fa-tasks"></i>
        <span>Assignment <i class="fas fa-angle-down dropdown-icon"></i></span>
      </a>
      <div class="sub-menu">
        <a routerLink="user-assignment" class="sub-item">
          <i class="fas fa-tasks"></i> Assignment
        </a>
        <a routerLink="task" class="sub-item">
          <i class="fas fa-tasks"></i> Tasks
        </a>
      </div>
    </div>

    <div class="item">
      <a routerLink="user-salary" class="menu-link">
        <i class="fas fa-money-check-alt"></i>
        <span>Payroll</span>
      </a>
    </div>

    <div class="item">
      <a routerLink="forget-password-user" class="menu-link">
        <i class="fas fa-lock"></i>
        <span>Change Password</span>
      </a>
    </div>
    <div class="item" (click)="logout()">
      <span class="menu-link">
      <i class="fas fa-lock"></i>
      <span>Logout</span>
    </span>
  </div>
  </div>
</div>