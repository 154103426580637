<div class="container2">
  <div class="breadcrumb-container" style="margin-top: 10px;">
    <div class="breadcrumb-item" [routerLink]="['/manager-dashboard/project-dashboard']">Assignment</div>
    <div class="breadcrumb-arrow">></div>
    <div class="breadcrumb-item" style="color:white">Task And Projects</div>
  </div>
  
  <div class="container">
    <div class="left-section">
      <div class="breadcrumb-container2">
        <div class="breadcrumb-item2" style="margin-top: -30px;">
          <div class="images">
            <img src="../../../../assets/icons/starting.png" alt="starting point"/>
            <img style="margin-left: 10px; margin-top: 4px;" src="../../../../assets/icons/connection.png" alt=""/>
          </div>
        </div>
        <div class="breadcrumb-arrow"><img src="../../../../assets/icons/starting1.png" alt="second"/></div>
        <div class="breadcrumb-item2">Project Details</div>
        <div class="breadcrumb-arrow"><img src="../../../../assets/icons/connect.png" alt="connect"/></div>
        <div class="breadcrumb-item2"><img src="../../../../assets/icons/sqaure.png" alt="square"/><span style="margin-left: 5px;"> Tasks And Stories</span></div>
      </div>
    </div>
    
    <div class="right-section" style="height: 500px; overflow-y: auto;">
      <h1 class="task">Task</h1>
      <div class="task-list" *ngFor="let assignment of assignment" >
        <div class="task-item">
          <div class="task-item-left" >
            <div class="task-header" style="display: flex; justify-content: space-between; align-items: center;">
              <div style="display: flex; align-items: center;">
                <h3 style="margin: 0; text-transform: capitalize;cursor: pointer;"(click)="toggleTaskDetails(assignment.id)" >{{assignment.name}}</h3>
              </div>
              <span class="task-date">Deadline - {{assignment.deadline | date: 'MMM d, hh:mm a'}}</span>
            </div>
            <p style="display: flex;">{{ assignment.description }}
            <!-- <p>File URLs: <span  *ngFor="let url of assignment.fileUrl">{{ url | json }}</span></p> -->
            <ng-container *ngIf="assignment.fileUrl">
              <div *ngFor="let url of assignment.fileUrl" class="file-container">
                <a [href]="url" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="../../../../assets/attach-file.png" 
                    alt="Attached file" 
                    class="attachment-icon" 
                  />
                </a>
              </div>
            </ng-container>
          </p>
            
          </div>
            <div class="task-item-right">
              <button class="trash-icon" (click)="openDialogStory(assignment.id)">
                <img src="../../../../assets/add.png" alt="add Story"/>
              </button>
            </div>
            <div class="task-item-right">
              <button class=""  (click)="updateTask(assignment.id)">
                <!-- <img src="../../../../assets/add.png" alt="add Story"/> -->
                 <mat-icon>edit</mat-icon>
              </button>
            </div>
          <div class="task-item-right">
            <button class="trash-icon" (click)="onDeleteTask(assignment.id)">
              <img src="../../../../assets/icons/bin_svgrepo.com.png" alt="Delete"/>
            </button>
          </div>
          
        </div>
      </div>

      <div class="pagination-container">
        <mat-paginator 
          [length]="totalElements" 
          [pageSize]="pageSize" 
          [pageSizeOptions]="[10, 20, 50, 100]" 
          (page)="onPageChange($event)">
        </mat-paginator>
      </div>
    </div>

    <div class="task-details-container" *ngIf="isTaskDetailsVisible" [ngClass]="{'open': isTaskDetailsVisible}">
      <div class="container" *ngIf="selectedTask" style="margin: 0 auto; max-width: 800px; padding: 20px; background: #282828;height: -webkit-fill-available;">
        <h1>Task Details <span style="float: right; font-size: 10px; border: 1px solid grey; padding: 0px 9px 0px 9px; border-radius: 20px;">{{selectedTask.priorityLevel}}</span></h1>
       
        <div class="task-details">
          <div class="new">
            <h3>Assigned Date</h3>
            <span class="date"><img src="../../../../assets/icons/calendar.png" alt="calendar"/>{{selectedTask.createdTime | date: 'd MMM yyyy'}}</span>
          </div>
        </div>

        <div class="task-details">
          <div class="new">
            <h3>Deadline Date</h3>
            <span class="date"><img src="../../../../assets/icons/calendar.png" alt="calendar"/>{{selectedTask.deadline | date: 'd MMM yyyy'}}</span>
          </div>
        </div>

        <div class="task-status">
          <h3>Assigned by:</h3>
          <div>{{selectedTask.assignedByName}}</div>
        </div>

        <!-- <div class="project-files" *ngIf="selectedTask && selectedTask.fileUrl?.length">
          <h3>Project Files</h3>
          <div class="file" *ngFor="let file of selectedTask.fileUrl">
            <a [href]="file" target="_blank">
              <img [src]="getFileIcon(file)" alt="File" style="width:20px;height:20px;" />
            </a>
            <p><a  [href]="file.fileUrl"><img src="../../../../assets/attach-file.png" target="_blank" alt="add file"/></a></p>
          </div>
         
        </div> -->

        <div class="task-status">
          <h3>Task Status</h3>
          <div class="status">{{selectedTask.status}}</div>
        </div>
        <div class="task-last-update">
          <h3>Task Last Update</h3>
          <div class="date">
            <img src="../../../../assets/icons/calendar.png" alt="calendar"/>{{selectedTask.updatedTime | date: 'd MMM yyyy'}}
          </div>
        </div>
        <div class="task-story">
          <h3>Task Story</h3>
          <div class="" style="overflow-y: auto; height: 200px;">
          <div class="story-container" *ngFor="let storyItem of story; let i = index" >
            <div class="story-item">
              <p style="color: white;background: #3e3c3c; padding: 10px;border-radius: 20px;">
                <img style="width:25px; height:25px" src="../../../../assets/icons/story.png" alt="story"/>
                <span>{{ storyItem }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
        
      </div>
    </div>
  </div>
</div>
<!-- *ngFor="let assignment of assignment" (click)="openStoryDialog(i, storyItem, assignment.id)" -->