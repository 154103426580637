<div class="container">
  <div class="row" >
    <div class="col-md-3"> <a [routerLink]="['/admin/HrAttendance']"> 
      <div class="card">
      <div class="card-content">
        <h3>Present Employee</h3>
          <h2><span style="color:white;font-size: 36px;font-weight: 700;line-height: 48px;letter-spacing: -2.2%;">{{ totalUserPresent }} </span><span > Employee</span></h2>
        </div>
      </div>
      </a>
    </div>
    <div class="col-md-3"> <a [routerLink]="['/admin/showuser-task']"> 
      <div class="card">
      <div class="card-content">
        <h3>Daily Task Stats</h3>
          <h2><span style="color:white;font-size: 36px;font-weight: 700;line-height: 48px;letter-spacing: -2.2%;">{{ totalPendingTasks }}</span> <span > Completed</span></h2>
        </div>
      </div>
      </a>
    </div>
    <div class="col-md-3"> <a [routerLink]="['/admin/show-meetings']"> 
      <div class="card">
      <div class="card-content">
        <h3>Meetings Scheduled</h3>
          <h2><span style="color:white;font-size: 36px;font-weight: 700;line-height: 48px;letter-spacing: -2.2%;">{{ todayMeetingCount }}</span> <span > <img src="../../../../assets/clock.png" alt="watch"></span></h2>
        </div>
      </div>
      </a>
    </div>
    <div class="col-md-3"> <a [routerLink]="['/admin/HrAttendance']"> 
      <div class="card">
      <div class="card-content">
        <h3>Current projects</h3>
          <h2><span style="color:white;font-size: 36px;font-weight: 700;line-height: 48px;letter-spacing: -2.2%;">{{ todayMeetingCount ?(todayMeetingCount):0}}</span> <span > <img src="../../../../assets/second-card.png" alt="watch"></span></h2>
        </div>
      </div>
      </a>
    </div>
  </div>
</div>

<app-event-chart></app-event-chart>
<div class="socials-analytics" style="margin-top: 20px;">
  <h3>Socials Analytics</h3>
  <div class="social-platform">
      <div class="platform">
         <img src="../../../../assets/facebook_svgrepo.com.png" alt="facebook"/>
          <span>FaceBook</span>
      </div>
      <div class="stats">{{latestData.fbFollowings?(latestData.fbFollowings):0}}<br><small>Following</small></div>
      <div class="stats">{{latestData.fbFollowings?(latestData.fbFollowers):0}} <br><small>Followers</small></div>
      <div class="stats">{{latestData.fbFollowers?(latestData.fbPosts):0}}<br><small>Post</small></div>
      <a href="https://www.facebook.com/InvestMango" target="_blank" class="access-link">Access Now</a>
  </div>
  <div class="social-platform">
      <div class="platform">
        <img src="../../../../assets/instagram_svgrepo.com.png"  alt="facebook"/>
          <span>Instagram</span>
      </div>
      <div class="stats">{{latestData.instaFollowings?(latestData.instaFollowings):0}}<br><small>Following</small></div>
      <div class="stats">{{latestData.instaFollowers?(latestData.instaFollowers):0}}<br><small>Followers</small></div>
      <div class="stats">{{latestData.instaPosts?(latestData.instaPosts):0}}<br><small>Post</small></div>
      <a href="https://www.instagram.com/investmango/" target="_blank" class="access-link">Access Now</a>
  </div>
  <div class="social-platform">
      <div class="platform">
        <img src="../../../../assets/youtube_svgrepo.com.png" alt="facebook"/>
          <span>YouTube</span>
      </div>
      <div class="stats">{{latestData.youtubeFollowings?(latestData.youtubeFollowings):0}}<br><small>Following</small></div>
      <div class="stats">{{latestData.youtubeFollowers?(latestData.youtubeFollowers):0}}<br><small>Followers</small></div>
      <div class="stats">{{latestData.youtubePosts?(latestData.youtubePosts):0}}<br><small>Post</small></div>
      <a href="https://www.youtube.com/@InvestMango" target="_blank" class="access-link">Access Now</a>
  </div>
</div>
<app-user-performance></app-user-performance>