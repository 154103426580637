import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MainService } from 'src/app/MyService/main.service';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { BreakModalComponent } from 'src/app/pages/user/break-modal/break-modal.component';

@Component({
  selector: 'app-show-breaks',
  templateUrl: './show-breaks.component.html',
  styleUrls: [
    './show-breaks.component.css',
    '/../../../shared/all-style/all-style.component.css',
  ],
})
export class ShowBreaksComponent implements OnInit {
  pageSize: number = 10;
  pageNumber: number = 0;
  totalElements: number = 0;
  id: string | null = localStorage.getItem('id');
  data: any;
  breakList: any[] = [];
  showFilterForm: boolean = false;
  startDate: string | null = null;
  endDate: string | null = null;
  isFiltered: boolean = false;
  employees: any[] = [];
  user_id: string | null = null;
  userRole: any;
  role: any;
  inLatLong:any;
  users:any;
  attendance:any;
  constructor(
    private mainService: MainService,
    private login: LoginService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userRole = this.login.getUser();
    this.role = this.userRole?.authorities[0]?.authority; // Ensure it doesn't throw an error
    console.log('User Role:', this.role);
    this.fetchData();
   this.getTodayAttendance();
    if (this.role === 'ADMIN') {
      this.fetchAllEmployees();
    } else {
      this.fetchManagerEmployees();
    }

  }

  fetchAllEmployees(): void {
    if (this.id) {
      this.mainService.AllEmployeeProfileList().subscribe(
        (res: any) => {
          this.employees = res || [];
          console.log('All Employees:', this.employees);
        },
        (err: any) => {
          console.error('Error fetching employees:', err);
        }
      );
    }
  }

  fetchManagerEmployees(): void {
    if (this.id) {
      this.mainService.managerAlllist(this.id, true).subscribe(
        (res: any) => {
          this.employees = res || [];
          console.log('Manager Employees:', this.employees);
        },
        (err: any) => {
          console.error('Error fetching employees:', err);
        }
      );
    }
  }
  getTodayAttendance() {
    this.mainService.getTodayAttendance(this.id).subscribe((res: any) => {
      if (res?.inTime) {
        this.attendance = res?.inTime;

        console.log("hh000000000000000000000",this.attendance)
      }
    });
  }

  // fetchAttendance():void{
  //   this.mainService.markAttendance(this.id).subscribe(
  //         (res: any) => {
  //           this.users = res;
  //           Swal.fire({
  //             icon: 'success',
  //             title: 'Success',
  //             text: this.users.message
  //           });
  //           console.log(JSON.stringify(this.users))
  //           this.getTodayAttendance(); // Reload attendance instead of page refresh
  //         },
  //         (error) => {
  //           console.error("Error marking attendance:", error.error.message);
  //           Swal.fire({
  //             icon: 'error',
  //             title: 'Error',
  //             text: error.error.message
  //           });
  //         }
  //       );
  // }
  fetchData(): void {
    const startDateParam = this.startDate
      ? this.mainService.toTimestamp(this.startDate)
      : null;
    const endDateParam = this.endDate
      ? this.mainService.toTimestamp(this.endDate)
      : null;
    const userId = this.user_id || this.id;

    this.mainService
      .getAllBreak(
        startDateParam,
        endDateParam,
        userId,
        this.pageNumber,
        this.pageSize
      )
      .subscribe(
        (res: any) => {
          this.data = res;
          this.breakList = this.data.content || [];
          console.log(JSON.stringify(this.breakList))
          this.totalElements = this.data.totalElements || 0;
          this.addFilteredSerialNumbers();
        },
        (error) => {
          console.error(error);
          Swal.fire(
            'Error',
            error.error?.message || 'Something went wrong',
            'error'
          );
        }
      );
  }

  resetFilter(): void {
    this.startDate = null;
    this.endDate = null;
    this.isFiltered = false;
    this.pageNumber = 0;
    this.fetchData();
  }

  addFilteredSerialNumbers(): void {
    this.breakList.forEach((item: any, index: number) => {
      item.serialNumber = index + 1;
    });
  }

  applyFilter(): void {
    if (!this.startDate || !this.endDate) {
      Swal.fire('Please select both start and end dates for filtering.');
      return;
    }
    this.isFiltered = true;
    this.pageNumber = 0;
    this.fetchData();
  }

  toggleFilterForm(): void {
    this.showFilterForm = !this.showFilterForm;
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.fetchData();
  }

  getShowingTo(): number {
    return Math.min((this.pageNumber + 1) * this.pageSize, this.totalElements);
  }
  openAddBreakModal( id:string): void {
    console.log("this.attendance",this.attendance)
    if (!this.attendance) { 
      console.error('Please firstly mark your attendance');
      Swal.fire('Error', 'Please mark your attendance first.', 'error');
      return;
    }
    this.dialog.open(BreakModalComponent, {
      width: '400px',
      data:{id:id}
    });
  }
  toggleBreakForm(userId:string) {
    
    if (!userId ) {
      console.error('currectRecordId is missing');
      return;
    }
    const updatedRecord = {
      id: userId ,
    };
    this.mainService
      .updateServiceRecord(userId, updatedRecord)
      .subscribe(
        (res: any) => {
          console.log('Service record updated:', res);
          location.reload();
        },
        (error) => {
          console.error('Error updating service record:', error);
        }
      );
  }
}
