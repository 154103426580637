import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MainService } from 'src/app/MyService/main.service';
// import { stat } from 'fs';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('slideInOut', [
      state('true', style({ height: '0', opacity: '0' })),
      state('false', style({ height: '*', opacity: '1' })),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  showDropdown = false;
  isHovered = false;
  userRole: any;
  showMyContainer: boolean = true;
  hideSidebar = true;
  isSidebarOpen:boolean = true;
  constructor(private login: LoginService, private router: Router,private mainService : MainService) {}

  ngOnInit(): void {
    this.userRole = this.login.getUserRole();
    this.mainService.menuState$.subscribe(state =>{
      this.isSidebarOpen = state;
    });
  }

  public logout() {
    Swal.fire({
      title: 'Confirm Sign Off',
      text: 'Are you sure you want to sign off?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, sign off',
      confirmButtonColor: '#ff4081',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.login.logout();
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        });
      }
    });
  
  }
  onMouseEnter() {
    this.showDropdown = true;
  }

  onMouseLeave() {
    this.showDropdown = false;
  }
  hide:boolean=true;

  toggleCard() {
    this.hide = !this.hide;
  }
  // toggleSidebar() {
  //   this.showMyContainer = !this.showMyContainer; // Toggle the boolean value
  // }
}
