<mat-dialog-content style="background-color: black;">
    <div class="modal-overlay" (click)="closeModal()"></div>
    <div class="modal-content">
      <!-- Close Button -->
       <div class="heading">
        <h3>Add Meeting</h3>
        <span class="close" (click)="closeModal()">&times;</span>
       </div>
      
  
      <!-- Form -->
      <form [formGroup]="meetingForm" (ngSubmit)="formSubmit()" class="form-container">
        <!-- Client Name -->
        <div class="form-group">
          <label for="clientName">Client Name:</label>
          <input type="text" id="clientName" formControlName="clientName" placeholder="Enter Client Name" />
          <div *ngIf="meetingForm.get('clientName').invalid && meetingForm.get('clientName').touched" class="error-message">
            Client name is required.
          </div>
        </div>

  
        <!-- Client Email -->
        <div class="form-group">
            <label for="clientEmail">Client Email:</label>
            <input type="text" id="clientEmail" formControlName="clientEmail" placeholder="Enter Client Email" />
            <div *ngIf="meetingForm.get('clientEmail').invalid && meetingForm.get('clientEmail').touched" class="error-message">
                Valid email is required.
            </div>
          </div>
       
  
        <!-- Client Phone -->
        <div class="form-group">
          <label for="clientPhone">Client Phone:</label>
          <input type="text" id="clientPhone" formControlName="clientPhone" placeholder="Enter Client Phone" />
          <div *ngIf="meetingForm.get('clientPhone').invalid && meetingForm.get('clientPhone').touched" class="error-message">
            Client phone is required.
          </div>
        </div>
  
        <!-- Client Address -->
        <div class="form-group">
          <label for="clientAddress">Client Address:</label>
          <input type="text" id="clientAddress" formControlName="clientAddress" placeholder="Enter Client Address" />
        </div>
  
        <!-- Channel Partner Name -->
        <div class="form-group">
          <label for="channelPartnerName">Channel Partner Name:</label>
          <input type="text" id="channelPartnerName" formControlName="channelPartnerName" placeholder="Enter Channel Partner Name" />
        </div>
  
        <!-- Feedback -->
        <div class="form-group">
          <label for="feedback">Feedback:</label>
          <textarea id="feedback" formControlName="feedback" placeholder="Enter Feedback"></textarea>
        </div>
  
        <!-- Project Details -->
        <div class="form-group">
          <label for="projectDetails">Project Details:</label>
          <textarea id="projectDetails" formControlName="projectDetails" placeholder="Enter Project Details"></textarea>
        </div>
  
        <!-- Source -->
        <div class="form-group">
          <label for="source">Source:</label>
          <input type="text" id="source" formControlName="source" placeholder="Enter Source" />
        </div>
  
        <!-- Submit Button -->
        <button type="submit" class="submit-button" [disabled]="meetingForm.invalid">
          Submit
        </button>
      </form>
    </div>
  </mat-dialog-content>
  