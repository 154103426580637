<mat-dialog-content>
    <div (click)="closeModal()"></div>
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <form (ngSubmit)="formSubmit()">
        <h2 class="form-heading text-center"> {{ isUpdateMode ? 'Update Task' : 'Add Task' }}</h2>
  
        <!-- <div class="form-group">
          <label for="project">Select User</label>
          <select name="project" id="project" [(ngModel)]="taskData.assignToId" class="form-control" required>
            <option value="" disabled selected>Select Project</option>
            <option *ngFor="let employee of data" [value]="employee.id">
              {{employee.userName}}
            </option>
          </select>
        </div> -->
  
        
  
        <div class="form-group">
          <label>Sub Task Name</label>
          <input type="text" [(ngModel)]="updateUser.subtaskName" name="subtaskName" class="form-control" required>
        </div>
        <div class="form-group">
            <label>Deadline</label>
            <input type="date" [(ngModel)]="updateUser.taskDeadline" name="deadline" class="form-control">
          </div>
        <div class="form-group">
          <label for="taskDescription">Task Description</label>
          <textarea [(ngModel)]="updateUser.subtaskDescription" name="taskDescription" class="form-control" placeholder="Enter task description" rows="5" required></textarea>
        </div>
  
        <!-- <div class="form-group">
          <label for="taskFiles">Drop Task Files</label>
          <ngx-dropzone (change)="onSelect($event)" class="custom-dropzone">
            <ngx-dropzone-label><i class="fas fa-cloud-upload-alt"></i><br>Drop Task Files</ngx-dropzone-label>
            <ngx-dropzone-image-preview *ngFor="let f of files" [file]="f" [removable]="true" (removed)="onRemove($event)">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </div> -->
        <div class="form-group">
          <label for="fileInput">Upload Files</label>
          <input
            type="file"
            id="fileInput"
            name="taskFiles"
            (change)="onSelect($event)"
            multiple
          />
          <div *ngFor="let file of files; let i = index" class="file-item">
            <span>{{ file.name }} ({{ file.type }})</span>
            <button type="button" (click)="removeFile(i)">Remove</button>
          </div>
        </div>
  
        <div class="form-group text-center">
          <button type="submit" class="btn" [disabled]="isSubmitting"> {{ isUpdateMode ? 'Update Task' : 'Add Task' }}</button>
          <mat-spinner *ngIf="isSubmitting" diameter="30"></mat-spinner>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  