import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css','/../../../shared/all-style/add-meeting-style.component.css']
})
export class AddTaskComponent implements OnInit {
  assignedById = localStorage.getItem('id');
  isSubmitting = false;
  employee: string;
  // deadLine: string;
  deadDate:String;
  deadTime:String;
  
  public user = {
    subject: '',
    fileurl: [],
    deadLine: '',
    deadDate:'',
    deadTime:'',
    assignedById: this.assignedById,
  };

  data: any;
  selectedFiles: File[] = [];

  constructor(private snack: MatSnackBar, private mainService: MainService, private router: Router) {}

  ngOnInit(): void {
    this.mainService.managerAlllist(this.assignedById, true).subscribe((res: any) => {
      this.data = res;
    });
  }

  onSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {

      // const maxFileSize = 5 * 1024 * 1024; 
      Array.from(input.files).forEach((file) => {
        // if (file.size > maxFileSize) {
        //   Swal.fire('Error', `File "${file.name}" exceeds 5 MB limit.`, 'error');
        // } else 
        if (file.type === 'application/pdf') {
          Swal.fire('Error', `File "${file.name}" is a PDF and cannot be uploaded.`, 'error');
        } else {
          this.selectedFiles.push(file);
        }
      });
    }
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
  }
  onEmployeeChange(event: Event): void {
    const selectedEmployeeId = (event.target as HTMLSelectElement).value;
    this.employee = selectedEmployeeId;
    console.log('Selected Employee ID:', selectedEmployeeId);
  }
  formSubmit() {
    if (this.selectedFiles.length === 0 && !this.user.subject) {
      Swal.fire('Error', 'Please select at least one file or enter a task', 'error');
      return;
    }
     
    this.isSubmitting = true;
    if (!this.user.deadDate || !this.user.deadTime) {
      Swal.fire('Error', 'Please fill in both the deadline date and time.', 'error');
      this.isSubmitting = false;
      return;
    }
    const deadlineTimestamp = new Date(`${this.user.deadDate}T${this.user.deadTime}`).getTime();
    this.user.deadLine = deadlineTimestamp.toString();  
    this.uploadFiles(deadlineTimestamp);
  }
  uploadFiles(deadlineTimestamp: number) {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    if (this.selectedFiles.length === 0) {
      this.addTask();
      return;
    }

    this.selectedFiles.forEach((file) => {
      const folderName = 'taskDocs';
      const data = new FormData();
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res) => {
          const secureUrl = res.message;
          documentsArray.push(secureUrl); 

          if (++processedFiles === this.selectedFiles.length) {
            this.user.fileurl = [...documentsArray];
            this.addTask();
          }
          
          
        },
        (error) => {
          Swal.fire('Error', 'File upload failed. Please try again.', 'error');
          this.isSubmitting = false;
        }
      );
    });
  }

  addTask() {
    const userId = this.employee || this.assignedById;
    if(userId === this.employee){
      this.mainService.assignTask(userId,this.user).subscribe((res)=>{
        Swal.fire('Success', 'Task Assigned  successfully', 'success');
        this.router.navigate(['manager-dashboard/task']);
        this.isSubmitting = false;
      },(error) => {
        Swal.fire('Error', 'Failed to Asign  task. Please try again.', 'error');
        this.isSubmitting = false;
      })
    }else{
    this.mainService.addtask(userId, this.user).subscribe(
      (res) => {
        Swal.fire('Success', 'Task added successfully', 'success');
        this.router.navigate(['manager-dashboard/task']);
        this.isSubmitting = false;
      },
      (error) => {
        Swal.fire('Error', 'Failed to add task. Please try again.', 'error');
        this.isSubmitting = false;
      }
    );
  }
  }
}
