<div class="full-page">
  <h4> Approved Leaves </h4>
<div class="header-row" style="display: flex; justify-content: space-between;  align-items: center;  width: 100%; height: 50px; border-radius: 8px;  box-shadow: 0 0 4px #958c8c;   border-bottom: none;color: whitesmoke">
  <h5>Leaves List</h5>
  <div class="button-group" style="display: flex; gap: 10px;">
    <button mat-button class="custom-button" (click)="toggleFilterForm()">
      <mat-icon>filter_list</mat-icon> Filter
    </button>    
  </div>
</div>
<div class="filter-form-wrapper" *ngIf="showFilterForm" >

  <div class="filter-btn">
    <h2 style="margin: 0;" style="color:black">Filter</h2>
    <button mat-icon-button (click)="toggleFilterForm()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form #filterForm="ngForm" (ngSubmit)="approvedfilter()" >
    <div class="form-group">
      <label for="dateRange" >Select Date:</label><br>
      <div style="display: flex;">
        <input
          id="startDate"
          name="startDate"
          [(ngModel)]="startDate"
          type="date"
          class="form-control"
          style=" margin-right: 10px;"
        />
        <input
          id="endDate"
          name="endDate"
          [(ngModel)]="endDate"
          type="date"
          class="form-control"
        />
      </div>
    </div>

    <div class="form-group" >
      <label for="status" >Status:</label><br>
      <select name="status" [(ngModel)]="status" class="selector" >
        <option value="">All</option>
        <option value="PENDING">Pending</option>
        <option value="REJECTED">Rejected</option>
        <option value="APPROVED">Approved</option>
      </select>
    </div>
    <div class="form-group">
      <label>Select Employee</label>
      <select id="userId" name="employeeId" [(ngModel)]="userId"  class="selector" >
        <option value="">Select Employee</option>
        <option *ngFor="let employee of user" [value]="employee.id">{{ employee.userName }} </option>
      </select>
    </div>
    <div class="filter-btn">
      <button mat-button color="warn" (click)="clearFilters()">Clear</button>
      <button mat-raised-button color="accent" type="submit" style="width: 100%;">Filter</button>
    </div>

  </form>

</div>
<div class="container2">

  <div class="scrollable-content">
      <table>
    <thead>
      <tr>
        <th>Leave Id</th>
        <th>Apply Date</th>
        <th>Employee Name</th>
        <th>Reason</th>
        <th>Comment</th>
        <th  style="width: 180px"> Requested Date </th>
        <th>Leave Type</th>
        <th>Admin Status Date</th>
        <th> By</th>
        <th>Manager Status Date</th>
        <th>Manager Status</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of data">
        <td>{{ data.id }}</td>
        <td>{{ data.createdDate | date: 'dd-MMM-yyyy hh:mm a' }}</td>
        <td>{{ data.userName }}</td>
        <td (click)="openUserModal(data.reason,data.status,data.userName,data.leaveType,data.createdDate)">{{ data.reason }}</td>
        <td>{{ data.comment }}</td>
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>event</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let date of data.leaveDates; let i = index">
            {{ date }}
          </button>
        </mat-menu>
        <td>{{getLeaveTypeText( data.leaveType )}}</td>
        <td>{{data.updatedDateTime | date: 'dd-MMM-yyyy hh:mm a'}}</td>
        <td>{{data.approvedByName }}</td>
        <td>{{data.managerUpdateTime | date: 'dd-MMM-yyyy hh:mm a'}}</td>
        <td>{{data.managerStatus}}</td>
        <td style="opacity: .9;" [style.background]="data.status === 'APPROVED' ? 'green' : (data.status === 'REJECTED' ? 'red' : 'white')" [style.color]="data.status === 'APPROVED' || data.status === 'REJECTED' ? 'white' : 'black'">{{data.status}}</td>
       </tr>
    </tbody>
  </table>
</div>
</div>

<div class="pagination-container">
  <div class="pagination-info">
    Showing {{ pageNumber * pageSize + 1 }} to {{ getShowingTo() }} of {{ totalElements }} Elements
  </div>
  <mat-paginator 
    [length]="totalElements" 
    [pageSize]="pageSize" 
    [pageSizeOptions]="[10, 20, 50, 100]" 
    (page)="onPageChange($event)">
  </mat-paginator>
</div>
</div>