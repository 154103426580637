import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { MainService } from 'src/app/MyService/main.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.css', '/../../../shared/all-style/add-meeting-style.component.css'],
})
export class AddTaskComponent implements OnInit {
  id = localStorage.getItem('id');
  isSubmitting = false;
  selectedFiles: File[] = [];

  constructor(private snack: MatSnackBar, private mainService: MainService, private router: Router) {}

  public user = {
    deadDate: '',
    deadTime: '',
    subject: '',
    fileurl: [],
    deadLine: 0,
  };

  ngOnInit(): void {}

  onSelect(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      // const maxFileSize = 300 * 1024;
      Array.from(input.files).forEach((file) => {
        // if (file.size > maxFileSize) {
        //   Swal.fire('Error', `File "${file.name}" exceeds 300 KB limit.`, 'error');
        // } else
         if (file.type === 'application/pdf') {
          Swal.fire('Error', `File "${file.name}" is a PDF and cannot be uploaded.`, 'error');
        } else {
          this.selectedFiles.push(file);
        }
      });
    }
  }

  removeFile(index: number): void {
    this.selectedFiles.splice(index, 1);
  }

  formSubmit(): void {
    if (this.selectedFiles.length === 0 && !this.user.subject) {
      Swal.fire('Error', 'Please select at least one file or enter a task', 'error');
      return;
    }

    if (this.user.deadDate && this.user.deadTime) {
      const combinedDateTime = new Date(`${this.user.deadDate}T${this.user.deadTime}`);
      this.user.deadLine = combinedDateTime.getTime();
    } else {
      Swal.fire('Error', 'Please select both date and time for the deadline.', 'error');
      return;
    }

    this.isSubmitting = true;
    this.uploadFiles();
  }

  uploadFiles(): void {
    const documentsArray: string[] = [];
    let processedFiles = 0;

    if (this.selectedFiles.length === 0) {
      this.addTask();
      return;
    }

    this.selectedFiles.forEach((file) => {
      const data = new FormData();
      const folderName = 'taskDocs';
      data.append('file', file);

      this.mainService.uploadFile(file, folderName).subscribe(
        (res) => {
          const secureUrl = res.message;
          documentsArray.push(secureUrl);

          if (++processedFiles === this.selectedFiles.length) {
            this.user.fileurl = [...documentsArray]; // Use the first uploaded file's URL.
            this.addTask();
          }
        },
        (error) => {
          console.error(error);
          this.isSubmitting = false;
        }
      );
    });
  }

  addTask(): void {
    this.mainService.addtask(this.id, this.user).subscribe(
      () => {
        Swal.fire('Success', 'Task added successfully', 'success');
        this.router.navigate(['user-dashboard/task']);
        this.isSubmitting = false;
      },
      (error) => {
        console.error(error);
        Swal.fire('Error', 'There was an error adding the task', 'error');
        this.isSubmitting = false;
      }
    );
  }
}
