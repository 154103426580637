<div class="card" >
    <div class="card-header">
      Hiring Details
      <span class="modal-close" (click)="onNoClick()">X</span>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="card1">
            <label>Designation</label>
            <div class="col">   
                <div class="tags">
                    <span class="tag-field">{{ selectedCandidate?.designation }}</span>
              </div>
            </div>
            <label>Budget</label>
            <div class="col">
                <div class="tags">
                        <span class="tag-field">{{ selectedCandidate?.budget || 'N/A' }}</span>
                  </div>
                </div>
          </div>
          <div class="card1">
          <label>Skill Keywords</label>
          <div class="col">
            <div class="tags">
                <span class="tag" *ngFor="let skill of selectedCandidate?.skills">{{skill}}</span>
              </div>
        
             </div>
        </div>
        <div class="card1">
          <label>Designation <br>Keywords</label>
          <div class="col">
            <div class="tags">
                <span class="tag" *ngFor="let keyword of selectedCandidate?.keywords">Developer</span>
              
              </div>
        
        </div>
        </div>
        <label>Job Description</label>  
        <div class="card1">
          
          <div class="col">
            <div class="tags">
                <textarea class="tag-field" style="width: 100%;border: none;">{{ selectedCandidate?.description }}</textarea>
          </div>
          </div>
        </div>
    </div>
    </div>
  </div>